import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {App} from '@environment/models/environment.model';

@Component({
  selector: 'app-business-functions-popup',
  templateUrl: './business-functions-popup.component.html',
  styleUrls: ['./business-functions-popup.component.scss'],
})
export class BusinessFunctionsPopupComponent {
  @Input() appList: App[];
  @Output() businessFunctionSwitch: EventEmitter<void> = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  onAppClick(app: App): void {
    window.open(app.url, '_blank');
    this.businessFunctionSwitch.emit();
  }

  sanitize(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
