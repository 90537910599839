import { createReducer, on } from '@ngrx/store';
import produce, { Draft } from 'immer';
import { HttpErrorResponse } from '@angular/common/http';
import { InitOptions } from './models/init-options.model';
import { initOptionsActions } from './init-options.actions';

export interface InitOptionsState extends Partial<InitOptions> {
  loading?: boolean;
  error?: HttpErrorResponse;
}

export const initialState: InitOptionsState = {
  loading: true,
};

export const initOptionsReducer = createReducer(
  initialState,
  on(initOptionsActions.loadInitOptions, state =>
    produce(state, (draftState: Draft<InitOptionsState>) => {
      draftState.loading = true;
    }),
  ),
  on(initOptionsActions.setInitOptions, (state, { options }) => {
      return produce(state, (draftState: Draft<InitOptionsState>) => {
        Object.assign(draftState, options);
        if (!options.multiFactorAuthenticationRequired) {
          draftState.loading = false;
        }
      });
    },
  ),
  on(initOptionsActions.loadInitOptionsError, (state, { error }) =>
    produce(state, (draftState: Draft<InitOptionsState>) => {
      draftState.loading = true;
      draftState.error = error;
    }),
  ),
  on(initOptionsActions.mfaDialogClosed, (state) => produce(state, (draftState: Draft<InitOptionsState>) => {
    draftState.loading = false;
  }))
);
