import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {initOptionsSelectors} from '@store/app/init-options/init-options.selectors';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() removable = false;
  @Input() ariaText = 'Usuń';
  @Output() removed = new EventEmitter();

  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  constructor(private store: Store) {
  }

  ngOnInit() {
  }

  get removeIcon(): Observable<string> {
    return this.uiVersion$.pipe(
      map(version => version === 'scania' ? 'cross' : 'close')
    );
  }


  removeItem(): void {
    this.removed.emit();
  }

}
