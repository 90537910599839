import { createAction, props } from '@ngrx/store';
import { UpdateConsentDecisionPayload } from '@store/consents/models/update-consent-decision-payload';
import { FailPayload, SendPayload, SuccessPayload } from '@store/_common/models/common.types';

const loading = createAction('[Consents] Update consent decision', props<SendPayload<UpdateConsentDecisionPayload>>());
const loaded = createAction('[Consents] Update consent decision success', props<SuccessPayload<any>>());
const failed = createAction('[Consents] Update consent decision failed', props<FailPayload>());
const clear = createAction('[Consents] Update consent decision clear state');

export const updateConsentDecisionActions = {
  loading,
  loaded,
  failed,
  clear,
};
