import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-box',
  templateUrl: './data-box.component.html',
  styleUrls: ['./data-box.component.scss']
})
export class DataBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
