import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BusinessFunctionsSwitcherService } from '../../services/business-functions-switcher.service';
import { map, takeUntil } from 'rxjs/operators';
import { merge, Observable, of, Subject } from 'rxjs';
import { environment } from '@environment/environment';
import { App } from '@environment/models/environment.model';

@Component({
  selector: 'app-business-functions-switcher',
  templateUrl: './business-functions-switcher.component.html',
  styleUrls: ['./business-functions-switcher.component.scss'],
})
export class BusinessFunctionsSwitcherComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('buttonElement') buttonElement: ElementRef;
  @ViewChild('popupElement') popupElement: ElementRef;
  popupShown = false;
  appList$: Observable<App[]>;

  constructor(private businessFunctionsSwitcherService: BusinessFunctionsSwitcherService) {}

  ngOnInit(): void {
    if (environment?.applicationLauncher?.type === `APP_LIST`) {
      if (environment.applicationLauncher.appListUrl) {
        this.setBusinessFunctionsSwitcherAppListResponse();
      } else if (environment.applicationLauncher.appList?.length) {
        this.appList$ = of(environment.applicationLauncher.appList);
      }
    }
  }

  private setBusinessFunctionsSwitcherAppListResponse(): void {
    this.appList$ = merge(
      this.businessFunctionsSwitcherService.getBusinessFunctionsSwitcherAppListResponse(environment.applicationLauncher.appListUrl),
      this.businessFunctionsSwitcherService.getReloadAppListObservable(environment.applicationLauncher.appListUrl),
    ).pipe(
      map((appList) => environment.applicationLauncher.appList.concat(appList)),
      takeUntil(this.destroy$)
    );
  }

  togglePopup(): void {
    this.popupShown = !this.popupShown;
  }

  onBusinessFunctionSwitch(): void {
    this.popupShown = false;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.popupShown) {
      const clickedInside =
        this.popupElement.nativeElement.contains(event.target as HTMLElement) ||
        this.buttonElement.nativeElement.contains(event.target as HTMLElement);
      if (!clickedInside) {
        this.togglePopup();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
