<div mat-dialog-title>
  <h2 class="app-dialog__heading">Uwaga</h2>
</div>
<div mat-dialog-content>
  <p class="app-paragraph">
    Z powodów bezpieczeństwa sesja wygasnie za <b>{{ sessionExpiryTime$ | async }} s.</b>
    Aby przedłużyć sesję kliknij w poniższy przycisk. W innym wypadku zostaniesz wylogowany.
  </p>
</div>
<div mat-dialog-actions fxLayoutAlign="center" fxLayoutGap="10px">
  <button (click)="extendSession()" class="button button--primary">
    Przedłuż
  </button>
</div>
