<div class="breadcrumb-bg"></div>
<div class="breadcrumb">
  <ul class="breadcrumb__list">
    <li class="breadcrumb__item" *ngFor="let breadcrumb of breadcrumbs;last as isLast">
      <app-breadcrumb-item [item]="breadcrumb" [isLast]="isLast"></app-breadcrumb-item>
    </li>
  </ul>
</div>



