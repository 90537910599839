<div class="donut" fxLayout="column">

  <div class="donut__content" fxLayout="row" fxLayoutAlign="center center">

    <div class="donut__chart">
      <ngx-charts-pie-chart *ngIf="(detailsObs$ | async)?.length" [results]="results" [doughnut]="true" [legend]="false"
        [view]="view" (window:resize)="onResize($event)" [arcWidth]="0.6" [scheme]="colorScheme">
      </ngx-charts-pie-chart>
    </div>

    <div class="donut__legend">

      <ul class="legend">
        <li class="legend__item" *ngFor="let item of details$ | async; let i = index"
          [ngClass]="{'legend__item--disabled': !item.selected}">
          <div class="legend__item--buttons">

            <button mat-icon-button [ngClass]="{'app-disabled':item.selected}" (click)="changeItemState(i, false)">
              <mat-icon>cancel</mat-icon>
            </button>
            <button mat-icon-button [ngClass]="{'app-disabled':!item.selected}" (click)="changeItemState(i, true)">
              <mat-icon>check_circle</mat-icon>
            </button>

          </div>

          <div class="legend__item--description">
            <p>
              {{ item.name | translate }}
            </p>
            <p *ngIf="item.selected">
              {{ item.value }}
            </p>
          </div>
        </li>
      </ul>
      <mat-divider></mat-divider>
      <dl class="legend__balance">
        <dt class="legend__balance--label">Saldo:</dt>
        <dd class="legend__balance--value">178 zł</dd>
      </dl>
    </div>
  </div>
</div>
