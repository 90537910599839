import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConsentDetailsDialogData } from '@features/profile/components/consent-details-dialog/consent-details-dialog.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash-es';
import {
  ConsentDecisionCampaign,
  UpdateConsentDecisionPayload,
} from '@store/consents/models/update-consent-decision-payload';
import { updateConsentDecisionActions } from '@store/consents/actions/update-consent-decision.actions';
import { updateConsentDecisionSelectors } from '@store/consents/selectors/update-consent-decision.selectors';
import { distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/abstract/base-component.abstract';

@Component({
  selector: 'app-to-decide-consents-dialog',
  templateUrl: './to-decide-consents-dialog.component.html',
  styleUrls: ['./to-decide-consents-dialog.component.scss'],
})
export class ToDecideConsentsDialogComponent extends BaseComponent {
  current = 0;

  currentData: ConsentDetailsDialogData;

  form: UntypedFormGroup;

  loading$ = this.store$.pipe(select(updateConsentDecisionSelectors.loading));
  success$ = this.store$.pipe(select(updateConsentDecisionSelectors.success));

  activeSteps: { [key: string]: boolean };

  isSubmitActive = false;

  get controls() {
    return (this.form.get('campaigns') as UntypedFormArray).controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConsentDetailsDialogData[],
    private dialogRef: MatDialogRef<ToDecideConsentsDialogComponent>,
    private fb: UntypedFormBuilder,
    private store$: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.setCurrentCustomer();

    this.form.valueChanges.pipe(startWith({}), distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(() => {
      this.activeSteps[this.current] = !this.form.value.campaigns[this.current].consents.filter(
        (item) => !item.decision
      ).length;
      this.isSubmitActive = !!this.form.value.campaigns.filter(
        (item) => !item.consents.filter((consent) => !consent.decision).length
      ).length;
    });

    this.success$
      .pipe(
        distinctUntilChanged(),
        filter((success: boolean) => !!success)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  skipStep(): void {
    const campaign = this.controls[this.current] as UntypedFormGroup;
    (campaign.get('consents') as UntypedFormArray).controls.forEach((control) => {
      control.get('decision').setValue(null);
    });
    this.nextStep();
  }

  nextStep(): void {
    if (this.current + 1 < this.dialogData?.length) {
      this.current++;
      this.setCurrentCustomer();
    } else {
      this.dialogRef.close();
    }
  }

  prevStep(): void {
    if (this.current > 0) {
      this.current--;
      this.setCurrentCustomer();
    }
  }

  onSubmit(): void {
    this.store$.dispatch(updateConsentDecisionActions.loading({ data: this.dataFactory() }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(updateConsentDecisionActions.clear());
  }

  private dataFactory(): UpdateConsentDecisionPayload {
    const campaigns = this.form.value.campaigns.filter(
      (item) => !item.consents.filter((consent) => !consent.decision).length
    );
    const groupByCustomerId = groupBy(campaigns, 'customerId');

    return {
      customers: Object.entries<ConsentDecisionCampaign[]>(groupByCustomerId).map((array) => ({
        id: +array[0],
        campaigns: array[1].map(({ id, consents }) => ({
          id,
          consents: consents.map(({ id: consentId, decision }) => ({ id: consentId, decision })),
        })),
      })),
    };
  }

  private setCurrentCustomer(): void {
    this.currentData = this.dialogData[this.current];
  }

  private createForm(): void {
    this.form = this.fb.group({
      campaigns: this.fb.array([]),
    });

    this.dialogData.forEach((item: any, index) => {
      const campaignsArray = this.form.get('campaigns') as UntypedFormArray;
      campaignsArray.push(
        this.fb.group({
          id: item.id,
          header: item.header,
          customerId: item.customer.id,
          customerName: item.customer.name,
          consents: this.fb.array([]),
        })
      );

      item.consents.forEach((consent) => {
        (campaignsArray.at(index).get('consents') as UntypedFormArray).push(
          this.fb.group({
            id: consent.id,
            header: consent.header,
            decision: consent.statusTranslationId,
          })
        );
      });
    });

    this.activeSteps = this.form.value.campaigns.reduce((prev, curr, index) => {
      return {
        ...prev,
        [index]: false,
      };
    }, {});
  }
}
