import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { finalize, map, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Injectable({
  providedIn: 'root',
})
export class TimerService {

  private readonly WARN_TIME_BEFORE_TOKEN_EXPIRES = 180;

  private reset$ = new Subject();
  private warn$ = new BehaviorSubject<boolean>(false);
  private timer$ = new BehaviorSubject<number>(0);

  /** @description Get flag to show warn dialog */
  get warnTime(): BehaviorSubject<boolean> {
    return this.warn$;
  }

  /** @description Get time before token expires */
  get currentSessionTime(): BehaviorSubject<number> {
    return this.timer$;
  }

  constructor(private keycloakService: KeycloakService, private tenantConfigService: TenantConfigService) {

    const startValue = this.keycloakSessionTime();
    this.timer$.next(this.keycloakSessionTime());

    this.reset$.pipe(
      startWith(0 as number),
      switchMap(() => timer(0, 1000)),
      map((value: number) => startValue - value),
      tap((value: number) => value < this.WARN_TIME_BEFORE_TOKEN_EXPIRES && this.warn$.next(true)),
      finalize(() => this.keycloakService.logout(this.tenantConfigService.appBaseUrl)),
      takeWhile((time: number) => time >= 1),
    ).subscribe((time: number) => {
      this.timer$.next(time);
    });

  }

  /** @description Refresh session */
  resetSession(): void {
    this.reset$.next();
    this.warn$.next(false);
  }

  private keycloakSessionTime(): number {
    const { refreshTokenParsed, timeSkew } = this.keycloakService.getKeycloakInstance();
    const actualSessionLiveTime = refreshTokenParsed.exp + timeSkew;
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return actualSessionLiveTime - currentTime;
  }
}
