import {Component, OnInit, Renderer2} from '@angular/core';
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app/app.reducer';
import { LoadingActions } from '@store/app/loading/loading.actions';
import { TimerService } from '@core/services/timer/timer.service';
import { appServerTimeActions } from '@store/app/server-time/server-time.actions';
import {initOptionsSelectors} from '@store/app/init-options/init-options.selectors';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {AngularFaviconService} from 'angular-favicon';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private timerService: TimerService,
    private renderer: Renderer2,
    private ngxFavicon: AngularFaviconService,
  ) {
  }

  ngOnInit(): void {

    this.uiVersion$.pipe(
      filter(val => !!val),
      distinctUntilChanged()
    ).subscribe(uiVersion => {
      if (uiVersion !== 'default' && uiVersion !== 'workspace') {
        this.ngxFavicon.setFavicon(`favicon_${uiVersion}.ico`, 'favicon.ico');
        this.renderer.addClass(document.body, `theme-${uiVersion}`);
        this.loadExternalStyles(uiVersion);
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart || event instanceof RouteConfigLoadStart) {
        this.store.dispatch(LoadingActions.routeStart());
        this.timerService.resetSession();
      } else if (event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd) {
        this.store.dispatch(LoadingActions.routeEnd());
      }
    });
    this.store.dispatch(appServerTimeActions.loading());
  }

  private loadExternalStyles(uiVersion: string) {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.href = `stylesheet-${uiVersion}.css`;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

}
