<form [formGroup]="form" (submit)="onSubmit()" #ngForm="ngForm">
  <div class="first-login-dialog__title" mat-dialog-title>
    <div class="first-login-dialog__title--wrapper">
      <div class="app-dialog__heading">eBOK</div>
      <button aria-label="Zamknij" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="welcome">
      <mat-icon [svgIcon]="'submenu-profile-approval'"></mat-icon>
      <div class="welcome-header">
        <b>{{ dialogData?.customer?.name }}</b>
      </div>
      <p *ngIf="dialogData?.consentCampaign?.header as header">{{ header }}</p>
      <p *ngIf="dialogData?.consent?.unit as unit">
        {{ unit.organizationUnitName ? unit.organizationUnitName : unit.name }}
      </p>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="spinner">
      <ng-container *ngIf="loading$ | async">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>
    </div>

    <ul class="consent-list">
      <ng-container formArrayName="consents">
        <li class="consent-list__item" *ngFor="let control of controls; index as i" [formGroupName]="i">
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label>{{ 'PROFILE.CONSENTS.PERSON.FIRST_NAME' | translate }}</mat-label>
              <input [formControl]="control.get('firstName')" matInput type="text" />
              <mat-error [appControlErrors]="control.get('firstName')"></mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'PROFILE.CONSENTS.PERSON.SURNAME' | translate }}</mat-label>
              <input [formControl]="control.get('surname')" matInput type="text" />
              <mat-error [appControlErrors]="control.get('surname')"></mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-form-field>
              <mat-label>{{ 'PROFILE.CONSENTS.PERSON.EMAIL' | translate }}</mat-label>
              <input [formControl]="control.get('email')" matInput type="text" />
              <mat-error [appControlErrors]="control.get('email')"></mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'PROFILE.CONSENTS.PERSON.PHONE' | translate }}</mat-label>
              <input [formControl]="control.get('phone')" matInput type="text" />
              <mat-error [appControlErrors]="control.get('phone')"></mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-around start">
            <p *ngIf="dialogData?.consent?.content as content; else ebokConsentHeader" [innerHTML]="content"></p>
            <ng-template #ebokConsentHeader>
              <p class="consent-list__paragraph">{{ consents[i].header || ('APP.GENERAL.NO_RESULT' | translate) }}</p>
            </ng-template>
            <mat-radio-group class="consent-list__radio" formControlName="decision">
              <mat-radio-button [value]="customerDecisionEnum.ACCEPTED">{{
                'APP.GENERAL.YES' | translate
              }}</mat-radio-button>
              <mat-radio-button [value]="customerDecisionEnum.DENIED">{{
                'APP.GENERAL.NO' | translate
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

  <ng-container *ngIf="form.dirty && mode === 'edit'; else untouched">
    <div fxLayoutAlign="flex-end" fxLayoutGap="10px" mat-dialog-actions>
      <button class="button button--secondary" mat-dialog-close type="button">
        {{ 'APP.GENERAL.CANCEL' | translate }}
      </button>
      <button class="button button--primary--state-success">{{ 'APP.GENERAL.APPLY' | translate }}</button>
    </div>
  </ng-container>

  <ng-template #untouched>
    <div fxLayoutAlign="flex-end" mat-dialog-actions>
      <button class="button button--secondary" mat-dialog-close type="button">
        {{ 'APP.GENERAL.CLOSE' | translate }}
      </button>
    </div>
  </ng-template>
</form>
