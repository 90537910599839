import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  REGULATIONS_REDUCER_TOKEN,
  regulationsProvider,
  regulationsReducerKey,
} from '@store/regulations/regulations.reducer';
import { RegulationsEffects } from '@store/regulations/regulations.efects';
import { RegulationsService } from '@store/regulations/regulations.service';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(regulationsReducerKey, REGULATIONS_REDUCER_TOKEN),
    EffectsModule.forFeature([
      RegulationsEffects,
    ]),
  ],
  providers: [
    regulationsProvider,
    RegulationsService,
  ],
})
export class RegulationsStoreModule {
}
