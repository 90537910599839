import { Env } from './models/environment.model';

export const environment: Env = {
  production: false,
  applicationLauncher: {
    type: 'APP_LIST',
    appList: [
      {
        name: 'My Scania',
        url: 'https://myscania.cs.scania.com',
        icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC45OTk5IDQuMjY3MjZDMTUuNjE4OCAzLjkwOTkzIDE2LjM4MTIgMy45MDk5NCAxNy4wMDAxIDQuMjY3MjdMMjcuMDAwMSAxMC4wNDE0QzI3LjYxODggMTAuMzk4NyAyOCAxMS4wNTg5IDI4IDExLjc3MzRWMjYuMDAwNUMyOCAyNy4xMDUxIDI3LjEwNDYgMjguMDAwNSAyNiAyOC4wMDA1SDIwQzE4Ljg5NTQgMjguMDAwNSAxOCAyNy4xMDUxIDE4IDI2LjAwMDVWMTkuMDAwNUgxNFYyNi4wMDA1QzE0IDI3LjEwNTEgMTMuMTA0NiAyOC4wMDA1IDEyIDI4LjAwMDVINkM0Ljg5NTQzIDI4LjAwMDUgNCAyNy4xMDUxIDQgMjYuMDAwNVYxMS43NzM0QzQgMTEuMDU4OSA0LjM4MTE2IDEwLjM5ODcgNC45OTk5MiAxMC4wNDE0TDE0Ljk5OTkgNC4yNjcyNlpNMTIgMjYuMDAwNVYxOC41MDA1QzEyIDE3LjY3MjEgMTIuNjcxNiAxNy4wMDA1IDEzLjUgMTcuMDAwNUgxOC41QzE5LjMyODQgMTcuMDAwNSAyMCAxNy42NzIxIDIwIDE4LjUwMDVWMjYuMDAwNUgyNlYxMS43NzM0TDE2IDUuOTk5MjdMNiAxMS43NzM0VjI2LjAwMDVIMTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K',
      }
    ]
  },
};
