import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UnauthorizedDialogComponent } from '@shared/components/unauthorized-dialog/unauthorized-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Permission } from '@core/enums/permission.enum';
import { MenuService } from '@core/services/menu/menu.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    private dialog: MatDialog,
    private menuService: MenuService
  ) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.authenticated) {
        this.keycloakAngular.login();
        return;
      }

      const requiredRoles = route.data.roles;
      let granted = false;

      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true;
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }

      if (granted === false) {
        this.router.navigate([ '/' ]);
      }
      resolve(granted);
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await this.keycloakAngular.isLoggedIn();

    return new Promise(res => {

      if (state.url === '/no-permissions') {
        res(true);
        return;
      }

      if (state.url === '/' && this.keycloakAngular.isUserInRole(Permission.USER_LOGIN)) {
        this.router.navigate([ this.menuService.baseRoute ]);
      }

      if (isLoggedIn && !this.keycloakAngular.isUserInRole(Permission.USER_LOGIN)) {
        this.router.navigate(['/no-permissions']);

        res(false);
      }

      res(true);
    });
  }
}

