<div mat-dialog-title fxLayoutAlign="space-between center">
  <h2 class="app-dialog__heading">{{ dialogData.contentTitle | translate }}</h2>
  <button mat-icon-button mat-dialog-close type="button" aria-label="Zamknij">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="confirm-dialog">
    <ng-container *ngIf="!(dialogData.success$ | async); else success">
      <app-loadable [loading]="dialogData.loading$ | async">

        <ng-container *ngIf="contentComponent; else textContent">
          <ng-container *ngComponentOutlet="contentComponent"></ng-container>
        </ng-container>

        <ng-template #textContent>
          <div class="confirm-dialog__icon">
            <mat-icon>help</mat-icon>
          </div>
          <p class="confirm-dialog__paragraph">{{ content | translate : { prop: dialogData?.translateProps } }}</p>
        </ng-template>

      </app-loadable>
    </ng-container>
  </div>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
  <ng-container *ngIf="!(dialogData.success$ | async); else successButtons">

    <button class="button button--small button--secondary" [disabled]="(dialogData.loading$ | async)" mat-dialog-close>{{ 'APP.GENERAL.NO' | translate }}</button>
    <button class="button button--small button--primary" [disabled]="(dialogData.loading$ | async)"
            (click)="dialogData.confirmed()">{{ submitText | translate }}</button>

  </ng-container>
</div>


<ng-template #successButtons>
  <div fxLayoutAlign="center" fxLayoutGap="10px">
    <button class="button button--primary" mat-dialog-close>{{'APP.GENERAL.OK' | translate }}</button>
  </div>
</ng-template>

<ng-template #success>
  <p class="confirm-dialog__paragraph-success">{{ successContent | translate: { prop: dialogData?.translateProps } }}</p>
</ng-template>
