import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { langActions } from './lang.actions';

@Injectable()
export class LangEffects {
  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
  ) { }

  
  changeCurrent$ = createEffect(() => this.actions$
    .pipe(
      ofType(langActions.changeCurrent),
      exhaustMap(({ currentLang }) => {
        // localStorage.setItem(LANG_LS_KEY, currentLang);

        return this.translateService.use(currentLang)
          .pipe(
            map(() => langActions.changeCurrentLoaded()),
            catchError(error => of(langActions.setError({ error, currentLang }))),
          );
      }),
    ));

  
  currentChanged$ = createEffect(() => this.actions$
    .pipe(
      ofType(langActions.changeCurrentLoaded),
      tap(() => {
        const currentLang = this.translateService.currentLang;

        for (const el in this.translateService.store.translations) {
          if (currentLang !== el) {
            this.translateService.resetLang(el);
          }
        }
      }),
    ), {
    dispatch: false,
  });

  
  onError$ = createEffect(() => this.actions$
    .pipe(
      ofType(langActions.setError),
      map(({ currentLang }) => langActions.changeCurrent({ currentLang }))
    ));
}
