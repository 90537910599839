<div class="wrapper">
  <button
    #buttonElement
    (click)="togglePopup()"
    class="functions"
    title="{{ 'Funkcje biznesowe' }}"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      id="function-menu-button"
    >
      <g id="Group_5853" data-name="Group 5853" transform="translate(-1782 -35)">
        <g
          id="Rectangle_564"
          data-name="Rectangle 564"
          transform="translate(1782 35)"
          fill="#fff"
          stroke="#072648"
          stroke-width="1"
        >
          <rect width="29" height="28" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="28" height="27" rx="1.5" fill="none" />
        </g>
        <g id="Group_5457" data-name="Group 5457" transform="translate(1789.812 41.778)">
          <g id="Group_5454" data-name="Group 5454" transform="translate(0.188 0.223)">
            <rect
              id="Rectangle_565"
              data-name="Rectangle 565"
              width="3"
              height="3"
              fill="#0b1b5d"
            />
            <rect
              id="Rectangle_566"
              data-name="Rectangle 566"
              width="3"
              height="3"
              transform="translate(5)"
              fill="#0b1b5d"
            />
            <rect
              id="Rectangle_567"
              data-name="Rectangle 567"
              width="3"
              height="3"
              transform="translate(10)"
              fill="#0b1b5d"
            />
          </g>
          <g id="Group_5455" data-name="Group 5455" transform="translate(0.188 5.039)">
            <rect
              id="Rectangle_565-2"
              data-name="Rectangle 565"
              width="3"
              height="3"
              transform="translate(0 0.184)"
              fill="#0b1b5d"
            />
            <rect
              id="Rectangle_566-2"
              data-name="Rectangle 566"
              width="3"
              height="3"
              transform="translate(5 0.184)"
              fill="#0b1b5d"
            />
            <rect
              id="Rectangle_567-2"
              data-name="Rectangle 567"
              width="3"
              height="3"
              transform="translate(10 0.184)"
              fill="#0b1b5d"
            />
          </g>
          <g id="Group_5456" data-name="Group 5456" transform="translate(0.188 9.857)">
            <rect
              id="Rectangle_565-3"
              data-name="Rectangle 565"
              width="3"
              height="3"
              transform="translate(0 0.365)"
              fill="#0b1b5d"
            />
            <rect
              id="Rectangle_566-3"
              data-name="Rectangle 566"
              width="3"
              height="3"
              transform="translate(5 0.365)"
              fill="#0b1b5d"
            />
            <rect
              id="Rectangle_567-3"
              data-name="Rectangle 567"
              width="3"
              height="3"
              transform="translate(10 0.365)"
              fill="#0b1b5d"
            />
          </g>
        </g>
      </g>
    </svg>
  </button>
  <div *ngIf="popupShown" #popupElement class="popup">
    <app-business-functions-popup
      [appList]="appList$ | async"
      (businessFunctionSwitch)="onBusinessFunctionSwitch()"
    >
    </app-business-functions-popup>
  </div>
</div>
