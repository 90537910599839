<div class="business-functions-popup-container" *ngIf="appList?.length > 0">
  <div class="business-function-cell" *ngFor="let app of appList" (click)="onAppClick(app)">
    <div>
      <div class="iconContainer">
        <img class="icon" [src]="sanitize(app.icon)" />
      </div>
      <div class="nameContainer">
        <div class="app-name">{{ app.name }}</div>
      </div>
    </div>
  </div>
</div>
