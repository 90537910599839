<div mat-dialog-title>
  <h2 class="app-dialog__heading">Brak dostępu</h2>
</div>
<div class="no-background" mat-dialog-content>

  <div class="confirm-dialog__icon">
    <mat-icon svgIcon="exclamation-mark-circle-ico"></mat-icon>
  </div>
  <p class="confirm-dialog__paragraph">Brak dostępu do panelu</p>


</div>
<div class="no-background" mat-dialog-actions fxLayoutAlign="center" fxLayoutGap="10px">
  <button class="button button--primary" (click)="logout()">Wróć do okna logowania</button>
</div>
