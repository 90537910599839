import { Component, Inject } from '@angular/core';
import { Customer } from '@features/profile/models/customer';
import { ConsentCampaign } from '@store/consents/models/consent-campaign';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConsentItem } from '@store/consents/models/consent-item';
import {
  UpdateConsentDecisionPayload,
  UpdateLandingPageConsentDecisionPayload,
} from '@store/consents/models/update-consent-decision-payload';
import { select, Store } from '@ngrx/store';
import { updateConsentDecisionActions } from '@store/consents/actions/update-consent-decision.actions';
import { updateConsentDecisionSelectors } from '@store/consents/selectors/update-consent-decision.selectors';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/abstract/base-component.abstract';
import { consentListActions } from '@store/consents/actions/consent-list.actions';
import { BaseModel } from '@shared/models/base-model';
import { OrganizationUnit, Person } from '@features/profile/models/organization-unit';

export interface ConsentDetailsDialogData extends BaseModel {
  mode?: 'edit' | 'preview';
  customer?: Customer;
  consentCampaign?: ConsentCampaign;
  consent?: {
    customerId: string;
    categoryCode: string;
    content: string;
    unit: OrganizationUnit;
    person: Person;
    isNameRequired: boolean;
    isEmailRequired: boolean;
    isPhoneRequired: boolean;
  };
  header?: string;
}

export enum CustomerDecisionEnum {
  ACCEPTED = 'CUSTOMER_DECISION_ACCEPTED',
  DENIED = 'CUSTOMER_DECISION_DENIED',
}

@Component({
  selector: 'app-consent-details-dialog',
  templateUrl: './consent-details-dialog.component.html',
  styleUrls: ['./consent-details-dialog.component.scss'],
})
export class ConsentDetailsDialogComponent extends BaseComponent {
  form: UntypedFormGroup;
  mode: 'edit' | 'preview' = 'edit';

  customerDecisionEnum = CustomerDecisionEnum;

  loading$ = this.store$.pipe(select(updateConsentDecisionSelectors.loading));
  success$ = this.store$.pipe(select(updateConsentDecisionSelectors.success));

  get controls() {
    return (this.form.get('consents') as UntypedFormArray).controls;
  }

  get consents(): ConsentItem[] {
    return this.dialogData?.consentCampaign?.consents;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConsentDetailsDialogData,
    private fb: UntypedFormBuilder,
    private store$: Store,
    private dialogRef: MatDialogRef<ConsentDetailsDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.closeDialogHandler();
    if (this.dialogData.mode) {
      this.mode = this.dialogData.mode;
      this.form.disable();
      if (this.mode === 'edit') {
        (this.form.get('consents') as UntypedFormArray).controls.forEach((control) => {
          control.get('decision').enable();
        });
      }
    }
  }

  onSubmit(): void {
    // @ts-ignore
    this.store$.dispatch(updateConsentDecisionActions.loading({ data: this.dataFactory() }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store$.dispatch(updateConsentDecisionActions.clear());
  }

  private dataFactory(): UpdateConsentDecisionPayload | UpdateLandingPageConsentDecisionPayload {
    if (this.dialogData.consent) {
      const { decision, firstName, surname, email, phone } = this.form.getRawValue().consents[0];
      return {
        customerId: this.dialogData.consent.customerId,
        ConsentDecisions: [
          {
            ConsentCategoryCode: this.dialogData.consent.categoryCode,
            organizationUnitCode: this.dialogData.consent.unit.code,
            Decision: decision === CustomerDecisionEnum.ACCEPTED ? 'Accepted' : 'Denied',
            firstName,
            surname,
            email,
            phone,
          },
        ],
      };
    }
    return {
      customers: [
        {
          id: this.dialogData.consentCampaign.customerId,
          campaigns: [
            {
              id: this.dialogData.consentCampaign.id,
              consents: this.form.getRawValue().consents,
            },
          ],
        },
      ],
    };
  }

  private createForm(): void {
    this.form = this.fb.group({
      consents: this.fb.array([]),
    });

    if (this.consents) {
      this.consents.forEach((elem: ConsentItem) => {
        (this.form.get('consents') as UntypedFormArray).push(
          this.fb.group({
            id: elem.id,
            decision: elem.statusTranslationId,
          })
        );
      });
    } else if (this.dialogData?.consent?.person) {
      const unit = this.dialogData.consent.unit;
      const person = this.dialogData.consent.person;
      let decision = person.decision;
      if (decision) {
        decision = decision === 'Accepted' ? CustomerDecisionEnum.ACCEPTED : CustomerDecisionEnum.DENIED;
      }
      (this.form.get('consents') as UntypedFormArray).push(
        this.fb.group({
          id: new UntypedFormControl(unit.id),
          decision: new UntypedFormControl(decision),
          firstName: new UntypedFormControl(
            person.firstName,
            this.dialogData.consent.isNameRequired ? [Validators.required] : undefined
          ),
          surname: new UntypedFormControl(
            person.surname,
            this.dialogData.consent.isNameRequired ? [Validators.required] : undefined
          ),
          email: new UntypedFormControl(
            person.email,
            this.dialogData.consent.isEmailRequired ? [Validators.required] : undefined
          ),
          phone: new UntypedFormControl(
            person.phone,
            this.dialogData.consent.isPhoneRequired ? [Validators.required] : undefined
          ),
        })
      );
    }
  }

  private closeDialogHandler(): void {
    this.success$
      .pipe(
        distinctUntilChanged(),
        filter((success: boolean) => !!success),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.store$.dispatch(consentListActions.loading());
        this.dialogRef.close();
      });
  }
}
