import { Directive, Input, Optional } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';

@Directive({
  selector: '[routerLink][disableLink]',
})
export class DisableRouterLinkDirective {

  constructor(
    @Optional() routerLink: RouterLink,
    @Optional() routerLinkWithHref: RouterLinkWithHref,
  ) {

    const link = routerLink || routerLinkWithHref;

    const onClick = link.onClick;

    link.onClick = (...args) => routerLinkWithHref ? false : true;
  }

}
