<div class="data-box">

  <div appHideIfEmpty class="card__top">
    <ng-content select="[top]"></ng-content>
  </div>

  <div appHideIfEmpty class="data-box__label">
    <ng-content select="[label]"></ng-content>
  </div>

  <div class="data-box__content">
    <ng-content select="[content]"></ng-content>
  </div>

</div>
