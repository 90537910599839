import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CustomerDecisionEnum } from '@features/profile/components/consent-details-dialog/consent-details-dialog.component';

@Component({
  selector: 'app-consents-dialog-form-item',
  templateUrl: './consents-dialog-form-item.component.html',
  styleUrls: [ './consents-dialog-form-item.component.scss' ],
})
export class ConsentsDialogFormItemComponent  {

  formRef: UntypedFormGroup;
  customerDecisionEnum = CustomerDecisionEnum;

  @Input() set form(value: UntypedFormGroup) {
    this.formRef = value;
  }

  get controls() {
    return (this.formRef.get('consents') as UntypedFormArray).controls;
  }

  constructor() {
  }
}
