<footer class="footer">
  <div class="footer__container content">
    <ng-container *ngIf="(uiVersion$ | async) !== 'ecminfinite'; else ecmInfiniteContactTemplate">
      <div class="column-1">
        <h5>Infinite Sp. z.o.o</h5>
        <p>ul. Projektowa 1, 20-209 Lublin</p>
        <p>{{ 'FOOTER.PHONE' | translate }}: 81 745 17 50 | {{ 'FOOTER.EMAIL' | translate }}: info@infinite.pl</p>
      </div>
    </ng-container>
    <ng-template #ecmInfiniteContactTemplate>
      <div class="column-1">
        <h5>Infinite IT Solutions SRL</h5>
        <p>PC Center, Etajul 4</p>
        <p>Șoseaua București-Ploiești nr. 9-13</p>
        <p>Sector 1, București</p>
        <p>{{ 'FOOTER.PHONE' | translate }}: 0756 030 582</p>
        <p>{{ 'FOOTER.EMAIL' | translate }}: sales@infinite-b2b.ro | office@infinite-b2b.ro</p>
      </div>
    </ng-template>
    <div class="column-2 social-col">
      <h5>Infinite Paperless Lite</h5>
      <a href="https://twitter.com/b2b_suite">
        <mat-icon svgIcon="social_twitter"></mat-icon>
      </a>
      <a href="https://www.facebook.com/infinitepaperless/">
        <mat-icon svgIcon="social_facebook"></mat-icon>
      </a>
      <a href="https://www.youtube.com/channel/UCrjaMWD1-3VKMy8WViZ4sNA?view_as=subscriber">
        <mat-icon svgIcon="social_yt"></mat-icon>
      </a>
      <a href="https://www.linkedin.com/company/infinite-sp-z-o-o-/mycompany/">
        <mat-icon svgIcon="social_lin"></mat-icon>
      </a>
    </div>
    <div class="column-6">
      <p>
        Copyright by <a href="https://infinite.pl/" target="_blank">Infinite Sp. z.o.o</a>
        {{ currDate | date : 'yyyy' }}
      </p>
      <p>
        <img src="../../../../assets/images/przelewy-24-white.svg" title="Logotyp Przelewy24" />
      </p>
    </div>
  </div>
</footer>
