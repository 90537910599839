import { Component } from '@angular/core';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import { langSelectors } from '@store/app/lang/lang.selectors';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { TenantConfigService } from '@core/services/tenant-config.service';
import { Router } from '@angular/router';
import { MenuService } from '@core/services/menu/menu.service';
import { langActions } from '@store/app/lang/lang.actions';
import { BaseComponent } from '@shared/abstract/base-component.abstract';
import { appUnreadMessagesSelectors } from '@store/app/unread-messages/unread-messages.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss'],
})
export class HeaderDefaultComponent extends BaseComponent {
  profileData;
  isMobileOpen = false;
  baseRoute = this.menuService.baseRoute;

  locationOptions$ = this.store.select(initOptionsSelectors.locationOptions);
  unreadMessagesTotalResults$ = this.store.select(appUnreadMessagesSelectors.totalResults);
  currentLang$ = this.store.select(langSelectors.currentLang);
  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  menu$ = this.uiVersion$.pipe(
    map((uiVersion) => {
      const menu = this.menuService.menu;
      if (uiVersion === 'dpd') {
        return menu.filter((item) => item?.path !== '/repository');
      }
      if (uiVersion === 'ecminfinite') {
        const order = [
          '/dashboard',
          '/messages',
          '/settlements',
          '/documents',
          '/payments',
          '/reports',
          '/service-desk',
          '/services',
          '/repository',
          '/profile',
          '/contact',
          '/knowledge-base',
        ];
        return menu
          .sort((a, b) =>
            order.indexOf(a.path) < order.indexOf(b.path) ? -1 : order.indexOf(a.path) > order.indexOf(b.path) ? 1 : 0
          )
          .map((item) => {
            if (item.path === '/documents') {
              item.path = '/contracts';
            }
            return item;
          });
      }
      return menu;
    })
  );

  constructor(
    private store: Store,
    private keycloakService: KeycloakService,
    private tenantConfigService: TenantConfigService,
    private router: Router,
    private menuService: MenuService
  ) {
    super();
    this.profileData = this.keycloakService.getKeycloakInstance().tokenParsed;
  }

  changeLang(lang): void {
    this.store.dispatch(langActions.changeCurrent({ currentLang: lang }));
  }

  toggleMenu(): void {
    this.isMobileOpen = !this.isMobileOpen;
  }

  logout(): void {
    sessionStorage.clear();
    this.keycloakService.logout(this.tenantConfigService.appBaseUrl);
  }
}
