import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import {
  MultiFactorAuthenticationService,
  VerifyMfaCodeResponse,
} from '@core/services/multi-factor-authentication/multi-factor-authentication.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-multi-factor-authentication-dialog',
  templateUrl: './multi-factor-authentication-dialog.component.html',
  styleUrls: ['./multi-factor-authentication-dialog.component.scss'],
})
export class MultiFactorAuthenticationDialogComponent {
  form = this.fb.group({
    smsCode: new UntypedFormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
  });
  codeGenerating = true;
  codeValidating = false;
  showRegenerateButton = false;
  codeVerifyResponse: VerifyMfaCodeResponse;
  failedValidationsCount = 0;
  codeExpiresIn = 0;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly mfaService: MultiFactorAuthenticationService,
    private readonly dialogRef: MatDialogRef<MultiFactorAuthenticationDialogComponent>,
    private readonly keycloakService: KeycloakService
  ) {
    this.generateCode();
  }

  validateCode(): void {
    if (!this.form.valid) {
      return;
    }
    this.codeValidating = true;
    this.codeVerifyResponse = null;
    this.mfaService
      .verifyMfaCode(this.form.value.smsCode)
      .pipe(take(1))
      .subscribe((result) => {
        if (result === 'CORRECT') {
          this.dialogRef.close();
        } else {
          this.codeVerifyResponse = result;
          this.codeValidating = false;
          this.failedValidationsCount = this.failedValidationsCount + 1;
          if (this.failedValidationsCount >= 3) {
            this.keycloakService.logout();
          }
          if (result === 'EXPIRED') {
            this.showRegenerateButton = true;
          }
        }
      });
  }

  onRegenerate(): void {
    this.codeGenerating = true;
    this.showRegenerateButton = false;
    this.generateCode();
  }

  private generateCode(): void {
    this.mfaService
      .generateMfaCode()
      .pipe(
        take(1),
        tap(({ codeExpirationDate }) => {
          this.codeGenerating = false;
          this.codeExpiresIn = Math.floor((new Date(codeExpirationDate).getTime() - new Date().getTime()) / 1000);
        }),
        switchMap(() =>
          interval(1000).pipe(
            tap(() => {
              if (this.codeExpiresIn > 0) {
                this.codeExpiresIn = this.codeExpiresIn - 1;
              }
            }),
            untilDestroyed(this)
          )
        )
      )
      .subscribe();
  }
}
