import {Provider, InjectionToken} from '@angular/core';
import {ActionReducer, combineReducers, createFeatureSelector} from '@ngrx/store';
import {loadingReducer, LoadingState} from './loading/loading.reducer';
import {BaseState} from '@store/_common/models/base-state';
import {InitOptionsState, initOptionsReducer} from './init-options/init-options.reducer';
import {langReducer, LangState} from './lang/lang.reducer';
import {operationReducer} from '@store/_common/reducers/operation.reducer';
import {appServerTimeActions} from '@store/app/server-time/server-time.actions';
import {BaseListState} from '@store/_common/models/base-list-state';
import {appUnreadMessagesActions} from '@store/app/unread-messages/unread-messages.actions';
import {listReducer} from '@store/_common/reducers/list.reducer';

export const UNREAD_MESSAGES_PARAMS_KEY = 'AUNMP_K';

export interface AppState {
  loading: LoadingState;
  initOptions: InitOptionsState;
  lang: LangState;
  serverTime: BaseState;
  unreadMessages: BaseListState;
}

export const appReducers = combineReducers({
  loading: loadingReducer,
  initOptions: initOptionsReducer,
  lang: langReducer,
  serverTime: operationReducer(appServerTimeActions),
  unreadMessages: listReducer(appUnreadMessagesActions, UNREAD_MESSAGES_PARAMS_KEY),
});

export function getReducers() {
  return appReducers;
}

export const appReducerKey = 'app';

export const appFeatureSelector = createFeatureSelector(appReducerKey);

export const APP_REDUCER_TOKEN = new InjectionToken<ActionReducer<AppState>>(appReducerKey);

export const appProvider: Provider = {provide: APP_REDUCER_TOKEN, useFactory: getReducers};
