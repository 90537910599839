import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AppTableComponentInterface } from '@shared/modules/app-table/models/app-table-component-interface';
import { PageEvent } from '@angular/material/paginator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { get, isNil, omit } from 'lodash-es';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({ template: '' })
export class AppTableComponent<T> implements AppTableComponentInterface<T>, AfterContentInit, OnChanges {

  currentPage: number;

  @ViewChild(MatTable, { static: false }) tableRef: MatTable<T>;

  @Input() dataSource: MatTableDataSource<T>;
  @Input() displayedColumns: string[];
  @Input() paginatorLength?: number;
  @Input() paginatorPageSize?: number;
  @Input() paginatorPageSizeOptions?: number[];
  @Input() activeFilters;
  @Input() params;
  @Input() loading = true;

  @Input() set paginatorCurrentPage(value: number) {
    this.currentPage = !!value ? value : 1;
  }

  @Input('refreshSelection') set refreshSelection(value: boolean) {
    if (value) {
      this.onClearSelection();
    }
  }

  @Output() updateParams = new EventEmitter();
  @Output() paginatorPage = new EventEmitter<PageEvent>();
  @Output() checkedChange = new EventEmitter();

  formFilters: UntypedFormGroup;
  sortControl = new UntypedFormControl();
  advancedFilters = false;

  selectedIds = new Set<number>();
  allRecords = false;

  selectedIdsByKey: { [key: string]: Set<number> } = {};

  get selectedIdsByPage(): Set<number> {
    return this.selectedIdsByKey[this.currentPage];
  }

  readonly minDate = new Date(1900, 0, 1);

  get isEmptyFilters(): boolean {
    return Object
      .values(this.formFilters.value)
      .filter(({ value }) => value)
      .length === 0;
  }

  get selected(): number[] {
    return [ ...this.selectedIds ];
  }

  get isDisableOptions(): boolean {
    return this.selected.length === 0;
  }

  get isAllSelected(): boolean {
    return this.selectedIdsByPage.size === get(this.dataSource, 'data', []).length;
  }

  get checkedVisible(): boolean {
    return this.paginatorLength > this.dataSource?.filteredData?.length;
  }

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.paginatorCurrentPage) {
      if (!this.selectedIdsByKey.hasOwnProperty(this.currentPage)) {
        this.selectedIdsByKey[this.currentPage] = new Set<number>();
      }
    }

    if (changes.dataSource && !isNil(this.dataSource)) {

      if (this.allRecords) {
        this.selectedIdsByKey[this.currentPage] = new Set<number>();
        this.dataSource.data.forEach(row =>
          this.selectedIdsByKey[this.currentPage].add(get(row, 'id')),
        );
      }

    }

    if (changes.paginatorPageSize) {
      this.selectedIdsByKey = {};
      this.selectedIdsByKey[this.currentPage] = new Set<number>();
    }
  }

  ngAfterContentInit(): void {
    this.formFilters.patchValue(omit(this.params, [ 'currentPage', 'pageSize' ]));
    this.sortControl.setValue(get(this.params, 'sort', 0));
  }

  onSubmitForm(): void {
    if (this.formFilters.dirty) {
      this.onUpdateParams({
        ...this.formFilters.value,
        currentPage: 1,
      });
    }
  }

  onSortChange(): void {
    this.onUpdateParams({ sort: this.sortControl.value });
  }

  onPageChange(event): void {
    this.onUpdateParams(event);
  }

  onRemove(item): void {
    this.formFilters.get(item.fieldName + '.value').setValue(null);
    this.onUpdateParams(this.formFilters.value);
  }

  onToggleAdvancedFilters(): void {
    this.advancedFilters = !this.advancedFilters;
  }

  onClearParams(): void {
    Object.keys(this.formFilters.controls).forEach(key => {
      (this.formFilters.controls[key] as UntypedFormGroup).get('value').setValue('');
    });
    this.onUpdateParams({
      ...this.formFilters.value,
      currentPage: 1,
    });
  }

  onToggleAllCheck(event: MatCheckboxChange): void {
    if (event.checked) {
      this.dataSource.data.forEach(row =>
        this.selectedIdsByKey[this.currentPage].add(get(row, 'id')),
      );
    } else {
      this.allRecords = false;
      this.dataSource.data.forEach(row =>
        this.selectedIdsByKey[this.currentPage].delete(get(row, 'id')),
      );
    }

    this.onCheckedEmit();
  }

  checkAllRecords(event?: MatCheckboxChange): void {
    this.allRecords = !this.allRecords;
    if (this.allRecords) {
      this.dataSource.data.forEach(row => this.selectedIdsByKey[this.currentPage].add(get(row, 'id')));
    } else {
      this.selectedIdsByKey = Object.keys(this.selectedIdsByKey)
        .filter((key: string) => +key === this.currentPage)
        .reduce((obj, key) => {
          obj[key] = new Set<number>();
          return obj;
        }, {});
    }
    this.onCheckedEmit();
  }

  onToggleCheck(element, event: MatCheckboxChange): void {

    if (event.checked) {
      this.selectedIdsByKey[this.currentPage].add(element.id);

      if (!this.checkedVisible && this.isAllSelected) {
        this.allRecords = true;
      }

    } else {
      this.allRecords = false;

      this.selectedIdsByKey = Object.keys(this.selectedIdsByKey)
        .filter((key: string) => +key === this.currentPage)
        .reduce((obj, key) => {
          obj[key] = this.selectedIdsByKey[key];
          return obj;
        }, {});

      this.selectedIdsByKey[this.currentPage].delete(element.id);
    }

    this.onCheckedEmit();
  }

  onClearSelection(): void {
    this.allRecords = false;
    this.selectedIdsByKey = {};
    this.selectedIdsByKey[this.currentPage] = new Set<number>();
  }

  private onUpdateParams(customParams): void {
    this.updateParams.emit(customParams);
  }

  private onCheckedEmit(): void {
    this.checkedChange.emit({
      ids: Object.entries(this.selectedIdsByKey).reduce((prev, [ , value ]) => [ ...prev, ...value ], []),
      isAllSelected: this.allRecords,
    });
  }
}
