<footer class="footer">
  <div class="footer__top">
    <div class="footer__main-links">
     <div class="footer__link">
       <a href="https://www.scania.com/pl/pl/home/admin/misc/legal.html" target="_blank" >WARUNKI I ZASADY</a>
     </div>
     <div class="footer__link">
      <a href="https://www.scania.com/pl/pl/home/admin/misc/privacy-statement.html" target="_blank" >POLITYKA PRYWATNOŚCI</a>
    </div>
    <div class="footer__link">
      <a href="https://www.scania.com/pl/pl/home/admin/misc/privacy-statement/cookies.html" target="_blank" >PLIKI COOKIES</a>
    </div>
    <div class="footer__link">
      <a href="https://www.scania.com/pl/pl/home/admin/misc/rodo.html" target="_blank" >RODO</a>
    </div>
    </div>

    <div class="footer__social-links">
      <div class="footer__link">
        <a href="https://www.facebook.com/scaniapl/" target="_blank">
          <mat-icon svgIcon="social_facebook"></mat-icon>
        </a>
      </div>

      <div class="footer__link">
        <a href="https://www.linkedin.com/company/scania-polska-s-a/" target="_blank">
          <mat-icon svgIcon="social_lin"></mat-icon>
        </a>
      </div>

      <div class="footer__link">
        <a href="https://mobile.twitter.com/scaniapolska" target="_blank">
          <mat-icon svgIcon="social_twitter"></mat-icon>
        </a>
      </div>

      <div class="footer__link">
        <a href="https://www.youtube.com/channel/UCaHy3CvNSg4n5C4pKvO6UFA" target="_blank">
          <mat-icon svgIcon="social_yt"></mat-icon>
        </a>
      </div>

      <div class="footer__link">
        <a href="https://www.instagram.com/scaniapolska/" target="_blank">
          <mat-icon svgIcon="social_ig"></mat-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="footer__bottom">
    <div class="footer__copyrights">© Copyright Scania 2022 All rights reserved</div>
    <div class="footer__logo">
      <a href="https://www.scania.pl" target="_blank">
        <img src="assets/images/logo_scania.png"/>
      </a>
    </div>
  </div>
</footer>
