import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { intersection } from 'lodash-es';
import { TenantConfigService } from '@core/services/tenant-config.service';
import { Permission } from '@core/enums/permission.enum';
import { MenuService } from '@core/services/menu/menu.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanLoad {

  constructor(
    protected keycloakService: KeycloakService,
    private router: Router,
    private tenantConfigService: TenantConfigService,
    private menuService: MenuService
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let permissions = this.keycloakService.getUserRoles();

    if (this.tenantConfigService.mocks) {
      permissions = [ ...permissions, Permission.PERM_MOCKS ];
    }

    const hasAccess = intersection(permissions, (route.data?.RequiredPermission || [])).length > 0;

    if (hasAccess) {
      return true;
    } else {
      this.router.navigate([ this.menuService.baseRoute ]);
      return false;
    }

  }


}
