import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { langSelectors } from '@store/app/lang/lang.selectors';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {

  langSubscription: Subscription;

  constructor(private store$: Store) {
    super('');

    this.langSubscription = this.store$.pipe(
      select(langSelectors.currentLang),
    ).subscribe((lang: string) => {
      this.setLocale(lang);
    });
  }

  parse(value, parseFormat): Moment | null {
    const mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const conformed = textMask.conformToMask(value, mask).conformedValue;
    const isInvalid = conformed.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').length === 0;

    if (isInvalid) {
      return undefined;
    }

    return conformed ? moment(conformed, parseFormat, true) : undefined;
  }

  ngOnDestroy(): void {
    this.langSubscription.unsubscribe();
  }
}
