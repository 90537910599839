<header [ngClass]="{ 'mobile-open': isMobileOpen }" class="header">
  <div class="navigation">
    <div class="navigation content">
      <div class="navigation__upper-level">
        <div class="logo-wrapper">
          <a [routerLink]="baseRoute">
            <img
              [src]="
                (uiVersion$ | async) === 'default'
                  ? 'assets/images/logo.png'
                  : 'assets/images/logo_' + (uiVersion$ | async) + '.png'
              " />
          </a>
        </div>
        <div class="">
          <div class="navigation__account">
            <div class="desktop-nav">
              <div class="person-icon">
                <mat-icon svgIcon="user"></mat-icon>
              </div>
              <div class="navigation__account--item user-info">
                <div>
                  <span class="login text--bold">{{ profileData?.given_name + ' ' + profileData?.family_name }}</span>
                </div>
                <span class="email">{{ profileData?.email }}</span>
              </div>
              <div class="time-pie">
                <button
                  (click)="logout()"
                  [attr.aria-label]="'APP.LOGOUT' | translate"
                  [title]="'APP.LOGOUT' | translate"
                  class="button button--small button--icon">
                  <mat-icon svgIcon="logout"></mat-icon>
                </button>
              </div>

              <div>
                <mat-select
                  (valueChange)="changeLang($event)"
                  *ngIf="(locationOptions$ | async)?.langList?.length"
                  [value]="currentLang$ | async"
                  class="lang-change">
                  <mat-select-trigger>
                    {{ 'LANGUAGE.' + (currentLang$ | async) | translate }}
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let lang of (locationOptions$ | async)?.langList"
                    [value]="lang"
                    class="lang-change-options">
                    {{ 'LANGUAGE.' + lang | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="hamburger">
              <div>
                <button (click)="toggleMenu()" mat-icon-button>
                  <mat-icon *ngIf="!isMobileOpen">dehaze</mat-icon>
                  <mat-icon *ngIf="isMobileOpen">clear</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation__header" [ngClass]="{ navigation__header__workspace: (uiVersion$ | async) === 'workspace' }">
      <nav class="content">
        <ul appNavMenu class="navigation__list" fxLayout="row">
          <li *ngFor="let item of menu$ | async" class="navigation__item group">
            <ng-container *ngIf="item?.children?.length; else dashboard">
              <button [routerLink]="item.path" class="group__title" disableLink routerLinkActive="group__title--active">
                <mat-icon [svgIcon]="item.icon"></mat-icon>
                <span>{{ item.name | translate }}</span>
              </button>
              <ul class="group__list group__list__workspace">
                <div class="group__list--content">
                  <li *ngFor="let child of item.children">
                    <a [routerLink]="child.path" routerLinkActive="group__title--active">
                      <mat-icon [svgIcon]="child.icon"></mat-icon>
                      <span>{{ child.name | translate }}</span>
                    </a>
                  </li>
                </div>
              </ul>
            </ng-container>
            <ng-template #dashboard>
              <button [routerLink]="item.path" class="group__title" role="link" routerLinkActive="group__title--active">
                <mat-icon
                  *ngIf="item.name === 'APP.NAVIGATION.MESSAGES'; else normalIcon"
                  [svgIcon]="item.icon"
                  [matBadge]="(unreadMessagesTotalResults$ | async) || null"
                  matBadgeColor="warn"
                  matBadgePosition="below">
                </mat-icon>
                <ng-template #normalIcon>
                  <mat-icon [svgIcon]="item.icon"></mat-icon>
                </ng-template>

                <span>{{ item.name | translate }}</span>
              </button>
            </ng-template>
          </li>
        </ul>
      </nav>
    </div>

    <div class="mobile-nav">
      <div class="mobile-actions">
        <div class="mobile-user">
          <div class="person-icon">
            <mat-icon svgIcon="user"></mat-icon>
          </div>
          <div class="navigation__account--item user-info">
            <div>
              <span class="login text--bold">{{ profileData?.given_name + ' ' + profileData?.family_name }}</span>
            </div>
            <span class="email">{{ profileData?.email }}</span>           
          </div>
        </div>
        <div class="time-pie">
          <button
            (click)="logout()"
            [attr.aria-label]="'APP.LOGOUT' | translate"
            [title]="'APP.LOGOUT' | translate"
            mat-icon-button>
            <mat-icon>power_settings_new</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
