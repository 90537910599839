import { BaseState } from '@store/_common/models/base-state';
import { ActionReducer, combineReducers, createFeatureSelector } from '@ngrx/store';
import { operationReducer } from '@store/_common/reducers/operation.reducer';
import { InjectionToken, Provider } from '@angular/core';
import { regulationsAcceptActions } from '@store/regulations/actions/accept.actions';
import { regulationsDetailsActions } from '@store/regulations/actions/details.actions';

export interface RegulationsState {
  accept: BaseState;
  details: BaseState;
  acceptedDetails: BaseState;
}

export const regulationsReducers = combineReducers({
  accept: operationReducer(regulationsAcceptActions),
  details: operationReducer(regulationsDetailsActions)
});

export function getReducers() {
  return regulationsReducers;
}

export const regulationsReducerKey = 'regulations';

export const regulationsFeatureSelector = createFeatureSelector(regulationsReducerKey);

export const REGULATIONS_REDUCER_TOKEN = new InjectionToken<ActionReducer<RegulationsState>>(regulationsReducerKey);

export const regulationsProvider: Provider = { provide: REGULATIONS_REDUCER_TOKEN, useFactory: getReducers };
