import { Pipe, PipeTransform } from '@angular/core';
import { AppGlobal } from '@shared/globals/app-global';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Moment } from 'moment';

@Pipe({
  name: 'appDatePipe',
})
export class AppDatePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {}

  transform(
    value: Date | string | number | Moment,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    if (moment.isMoment(value)) {
      value = value.toDate();
    }
    return value
      ? this.datePipe.transform(value, format ? format : AppGlobal.angularDateFormat, timezone, locale)
      : null;
  }
}
