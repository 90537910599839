import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  INACTIVE = 'NON_ACTIVE',
}

@Directive({
  selector: '[appStatus]',
})
export class StatusDirective implements OnInit {
  @Input() appStatus: number;

  private readonly statusCodes = {
    [StatusEnum.ACTIVE]: 'APP.STATUS.ACTIVE',
    [StatusEnum.NEW]: 'APP.STATUS.NEW',
    [StatusEnum.INACTIVE]: 'APP.STATUS.INACTIVE',
  };

  private readonly statusClasses = {
    [StatusEnum.ACTIVE]: 'status--active',
    [StatusEnum.NEW]: 'status--new',
    [StatusEnum.INACTIVE]: 'status--inactive',
  };

  constructor(private el: ElementRef, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.el.nativeElement.classList.add(this.statusClasses[this.appStatus]);
    this.el.nativeElement.innerHTML = this.translateService.instant(this.statusCodes[this.appStatus]);
  }
}
