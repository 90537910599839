import get from 'lodash-es/get';
import { UrlDescriptor } from '@shared/abstract/url-descriptor.interface';

export class DescriptorSerializer {
  serialize(urlDescriptor: UrlDescriptor): string {
    if (urlDescriptor instanceof Array) {
      const serializedString = urlDescriptor.reduce((prev: string, curr: UrlDescriptor) => prev + curr.serialize(), '');
      const lastConnection = get(urlDescriptor[urlDescriptor.length - 1], 'connection', '');
      const removedLastConnection = serializedString.slice(0, serializedString.length - lastConnection.length);

      return removedLastConnection;
    }

    return urlDescriptor.serialize();
  }
}
