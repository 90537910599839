import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsoluteLoaderComponent } from '@shared/modules/loaders/components/absolute-loader/absolute-loader.component';
import { LoadableComponent } from '@shared/modules/loaders/components/loadable/loadable.component';


@NgModule({
  declarations: [
    AbsoluteLoaderComponent,
    LoadableComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AbsoluteLoaderComponent,
    LoadableComponent
  ]
})
export class LoadersModule {
}
