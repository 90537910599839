import { createSelector } from '@ngrx/store';
import { regulationsFeatureSelector, RegulationsState } from '@store/regulations/regulations.reducer';


const selector = createSelector(
  regulationsFeatureSelector,
  (state: RegulationsState) => state.details,
);

export const loading = createSelector(
  selector,
  (state) => state.loading,
);

export const success = createSelector(
  selector,
  (state) => state.success,
);

export const errors = createSelector(
  selector,
  (state) => state.errors,
);

export const data = createSelector(
  selector,
  (state) => state.data,
);

export const regulationsDetailsSelectors = {
  loading,
  errors,
  success,
  data
};
