<form [formGroup]="form" (submit)="isSubmitActive && onSubmit()" #ngForm="ngForm">
  <div class="first-login-dialog__title" mat-dialog-title>
    <div class="first-login-dialog__title--wrapper">
      <div class="app-dialog__heading">eBOK</div>
      <button aria-label="Zamknij" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="welcome">
      <mat-icon [svgIcon]="'submenu-profile-approval'"></mat-icon>
      <div class="welcome-header"><b>{{ currentData?.customer?.name }}</b></div>
      <p>{{ currentData?.header }}</p>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="spinner">
      <ng-container *ngIf="loading$| async">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>
    </div>

    <div style="min-height: 250px">
      <app-consents-dialog-form-item [form]="controls[current]"></app-consents-dialog-form-item>
    </div>

  </div>


  <div fxLayoutAlign="space-between center" fxLayoutGap="10px" mat-dialog-actions>

    <button (click)="skipStep()" class="button button--secondary button--small" type="button">
      Pomiń
    </button>

    <div fxLayoutGap="10px">
      <button class="button button--small button--secondary" type="button" (click)="prevStep()" *ngIf="current >0">
        Poprzedni
      </button>
      <button class="button button--small button--primary--state-success" type="button" (click)="nextStep()"
              [disabled]="!activeSteps[current]"
              *ngIf="current+1 < dialogData?.length">
        Następny
      </button>

      <button class="button button--small button--primary--state-success"
              [disabled]="!isSubmitActive"
              *ngIf="current+1 >= dialogData?.length">
        Zapisz
      </button>
    </div>


  </div>


</form>
