import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AppService} from './app.service';
import {appServerTimeActions} from './server-time/server-time.actions';
import {appUnreadMessagesActions} from '@store/app/unread-messages/unread-messages.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private service: AppService,
  ) {
  }

  
  serverTime$ = createEffect(() => this.actions$.pipe(
    ofType(appServerTimeActions.loading),
    switchMap(() => this.service.serverTime()
      .pipe(
        map((response) => appServerTimeActions.loaded({ response })),
        catchError((errors) => {
          return of(appServerTimeActions.failed(errors));
        }),
      ),
    ),
  ));

  
  messageList = createEffect(() => this.actions$.pipe(
    ofType(appUnreadMessagesActions.loading),
    switchMap(() => this.service.messageList()
      .pipe(
        map((result) => appUnreadMessagesActions.loaded(result)),
        catchError((errors) => {
          return of(appUnreadMessagesActions.failed(errors));
        }),
      ),
    ),
  ));

}
