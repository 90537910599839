import { createSelector } from '@ngrx/store';
import {appFeatureSelector, AppState} from '@store/app/app.reducer';


const selector = createSelector(
  appFeatureSelector,
  (state: AppState) => state.unreadMessages,
);

export const loading = createSelector(
  selector,
  (state) => state.loading,
);

export const totalResults = createSelector(
  selector,
  (state) => state.totalRecords,
);

export const appUnreadMessagesSelectors = {
  loading,
  totalResults,
};
