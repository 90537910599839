import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {InitOptions} from './models/init-options.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InitOptionsService {
  constructor(private http: HttpClient) {
  }

  getInitOptions = (): Observable<InitOptions> => {
    return this.http.get<InitOptions>('/api/core/user/Tenants/setup');
  }

  createUserToken(): void {
    this.http.post('/api/core/user/Users/create-user-token', null).subscribe();
}
}
