import { createAction, props } from '@ngrx/store';
import { FailPayload, SendPayload, SuccessPayload } from '@store/_common/models/common.types';
import { Regulation } from '@store/regulations/models/regulation';

const loading = createAction('[Regulations details] Loading');
const loaded = createAction('[Regulations details] Success',props<SuccessPayload<Regulation[]>>());
const failed = createAction('[Regulations details] Failed', props<FailPayload>());
const clear = createAction('[Regulations details] Clear state');

export const regulationsDetailsActions = {
  loading,
  loaded,
  failed,
  clear,
};
