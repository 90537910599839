import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-spinner',
  templateUrl: './button-spinner.component.html',
  styleUrls: ['./button-spinner.component.scss']
})
export class ButtonSpinnerComponent {

  @Input() diameter = 24;
  @Input() strokeWidth = 3;

}
