import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInLayoutComponent } from '@core/components/logged-in-layout/logged-in-layout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { PermissionGuard } from '@core/guards/permission.guard';
import { Permission } from '@core/enums/permission.enum';

const routes: Routes = [
  {
    path: '',
    component: LoggedInLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'no-permissions',
        loadChildren: () =>
          import('@features/no-permissions-info/no-permissions-info.module').then((m) => m.NoPermissionsInfoModule),
      },
      {
        canLoad: [PermissionGuard],
        path: 'dashboard',
        loadChildren: () => import('@features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: {
          RequiredPermission: [Permission.PERM_BASE_DASHBOARD],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'settlements',
        loadChildren: () => import('@features/settlements/settlements.module').then((m) => m.SettlementsModule),
        data: {
          RequiredPermission: [Permission.PERM_DOCUMENTS_INVOICES],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'contact',
        loadChildren: () => import('@features/contact/contact.module').then((m) => m.ContactModule),
        data: {
          RequiredPermission: [Permission.PERM_CONTACT],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'documents',
        loadChildren: () => import('@features/documents/documents.module').then((m) => m.DocumentsModule),
        data: {
          RequiredPermission: [Permission.PERM_DOCUMENTS_CONTRACTS],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'contracts',
        loadChildren: () => import('@features/contracts/contracts.module').then((m) => m.ContractsModule),
        data: {
          RequiredPermission: [Permission.PERM_DOCUMENTS_CONTRACTS],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'contracts-attachments',
        loadChildren: () =>
          import('@features/contracts-attachments/contracts-attachments.module').then(
            (m) => m.ContractsAttachmentsModule
          ),
        data: {
          RequiredPermission: [Permission.PERM_DOCUMENTS_CONTRACTS],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'customer-partners-attachments',
        loadChildren: () =>
          import('@features/customer-partners-attachments/customer-partners-attachments.module').then(
            (m) => m.CustomerPartnersAttachmentsModule
          ),
        data: {
          RequiredPermission: [Permission.PERM_DOCUMENTS_CONTRACTS],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'repository',
        loadChildren: () => import('@features/repository/repository.module').then((m) => m.RepositoryModule),
        data: {
          RequiredPermission: [Permission.PERM_REPOSITORY],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'knowledge-base',
        loadChildren: () => import('@features/knowledge-base/knowledge-base.module').then((m) => m.KnowledgeBaseModule),
        data: {
          RequiredPermission: [Permission.PERM_KNOWLEDGEBASE],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'messages',
        loadChildren: () => import('@features/messages/messages.module').then((m) => m.MessagesModule),
        data: {
          RequiredPermission: [Permission.PERM_MESSAGES],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'reports',
        loadChildren: () => import('@features/reports/reports.module').then((m) => m.ReportsModule),
        data: {
          RequiredPermission: [Permission.PERM_REPORT],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'payments',
        loadChildren: () => import('@features/payments/payments.module').then((m) => m.PaymentsModule),
        data: {
          RequiredPermission: [Permission.PERM_PAYMENTS],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'profile',
        loadChildren: () => import('@features/profile/profile.module').then((m) => m.ProfileModule),
        data: {
          RequiredPermission: [Permission.PERM_PROFILE],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'services',
        loadChildren: () => import('@features/services/services.module').then((m) => m.ServicesModule),
        data: {
          RequiredPermission: [Permission.PERM_SVC],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'support',
        loadChildren: () => import('@features/support/support.module').then((m) => m.SupportModule),
        data: {
          RequiredPermission: [Permission.PERM_SERVICE],
        },
      },
      {
        canLoad: [PermissionGuard],
        path: 'service-desk',
        loadChildren: () => import('@features/service-desk/service-desk.module').then((m) => m.ServiceDeskModule),
        data: {
          RequiredPermission: [Permission.PERM_SERVICE],
        },
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
