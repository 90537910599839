import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { InitOptions } from '@store/app/init-options/models/init-options.model';
import { Subscription } from 'rxjs';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable()
export class DateService implements OnDestroy {
  private timeFormat: string;
  private timezone: string;
  subscription: Subscription;

  constructor(
    private store$: Store<InitOptions>,
  ) {
    this.subscription = this.store$
      .select(initOptionsSelectors.time)
      .subscribe(({ timeFormat, actualTimezone }) => {
        this.timeFormat = timeFormat;
        this.timezone = actualTimezone;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTimeZone(): string {
    return cloneDeep(this.timezone);
  }

  getTimeFormat(): string {
    return cloneDeep(this.timeFormat);
  }

  getFormatAndZone(): { timezone: string, timeFormat: string } {
    const { timeFormat, timezone } = this;

    return cloneDeep({ timeFormat, timezone });
  }
}
