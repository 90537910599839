import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Injectable()
export class ClientErrorHandlerService extends ErrorHandler {
  constructor(private http: HttpClient, private tenantConfigService: TenantConfigService) {
    super();
  }

  handleError(error: Error) {
    console.error('err', error);
    if (error.stack && this.tenantConfigService.logFrontErrors) {
      this.http
        .post<void>('/api/core/user/Logs/log-front-event', {
          code: 'UI_CLIENT_ERROR',
          description: JSON.stringify(error.stack),
          level: 'ERROR',
        })
        .subscribe();
    }
  }
}
