import { createAction, props } from '@ngrx/store';
import { FailPayload, SuccessPayload } from '@store/_common/models/common.types';

const loading = createAction('[App] Load server time');
const loaded = createAction('[App] Load server time success', props<SuccessPayload<string>>());
const failed = createAction('[App] Load server time failed', props<FailPayload>());
const clear = createAction('[App] Load server time clear state');

export const appServerTimeActions = {
  loading,
  loaded,
  failed,
  clear,
};
