import { HttpRequest } from '@angular/common/http';

export function isUrlOnExceptionList(exceptionList: ExceptionUrls, req: HttpRequest<any>): boolean {
  return exceptionList.some(
    (exUrl) =>
      (exUrl.exactMatch ? req.url.endsWith(exUrl.partUrl) : req.urlWithParams.includes(exUrl.partUrl)) &&
      (exUrl.method ? exUrl.method.toUpperCase() === req.method.toUpperCase() : true)
  );
}

export type ExceptionUrl = {
  partUrl: string;
  method?: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
  exactMatch?: true;
};

export type ExceptionUrls = ExceptionUrl[];
