import {Pipe, PipeTransform} from '@angular/core';
import {StringUtils} from '../../utils/string.utils';
import {NumericUtils} from '../../utils/numeric.utils';

@Pipe({
  name: 'numberFormat'
})
export class NumberPipe implements PipeTransform {
  private defaultSeparator = ' ';
  private defaultFractionDigitsMin = 0;
  private defaultFractionDigitsMax = 2;
  private defaultFloatingPointSeparator = ',';


  transform(value: any, fractionDigitsMin?: number, fractionDigitsMax?: number,
            integerSeparator?: string, floatingPointSeparator?: string): string {

    if (isNaN(parseFloat(value))) {
      value = 0;
    }

    if (!fractionDigitsMin && fractionDigitsMin !== 0) {
      fractionDigitsMin = this.defaultFractionDigitsMin;
    }
    if (!integerSeparator) {
      integerSeparator = this.defaultSeparator;
    }
    if (!floatingPointSeparator) {
      floatingPointSeparator = this.defaultFloatingPointSeparator;
    }
    if (!fractionDigitsMax && fractionDigitsMax !== 0) {
      fractionDigitsMax = this.defaultFractionDigitsMax;
    }

    const rounded: string = NumericUtils.roundValue(parseFloat(value), fractionDigitsMin, fractionDigitsMax);

    return this.format(rounded, integerSeparator, floatingPointSeparator);
  }

  private format(value: string, separator: string, floatingPointSeparator: string): string {
    const lastIntegerIndex = StringUtils.indexOfDecimalPoint(value);
    value = value.replace('.', floatingPointSeparator);

    return StringUtils.insertSeparators(value.substring(0, lastIntegerIndex), separator) + value.substring(lastIntegerIndex);

  }

}
