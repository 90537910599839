<div mat-dialog-title fxLayout="column">
  <div fxLayoutAlign="space-between center">
    <div class="app-dialog__heading">{{ 'MFA_AUTHENTICATION.DIALOG_TITLE' | translate }}</div>
  </div>
</div>
<form [formGroup]="form" (submit)="validateCode()">
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="1rem">
    <span>{{ 'MFA_AUTHENTICATION.CONTENT' | translate }}</span>
    <mat-form-field fxFlex="100" *ngIf="!codeGenerating; else codeGeneratingTemplate">
      <mat-label>{{ 'MFA_AUTHENTICATION.SMS_CODE' | translate }}</mat-label>
      <input [formControl]="form.get('smsCode')" matInput type="text" maxlength="6" mask="000000" />
      <mat-error [appControlErrors]="form.get('smsCode')"></mat-error>
    </mat-form-field>

    <span *ngIf="codeExpiresIn">
      {{ 'MFA_AUTHENTICATION.CODE_EXPIRES_IN' | translate }}
      {{ codeExpiresIn | timeFormat : true }}
    </span>

    <span class="mat-error" *ngIf="codeVerifyResponse">
      {{ 'MFA_AUTHENTICATION.RESPONSE.' + codeVerifyResponse | translate }}
    </span>

    <button
      class="button button--secondary--state-success"
      *ngIf="showRegenerateButton"
      (click)="onRegenerate()"
      type="button">
      {{ 'MFA_AUTHENTICATION.REGENERATE_CODE' | translate }}
    </button>

    <ng-template #codeGeneratingTemplate>
      <div fxLayout="row" fxLayoutGap="1rem">
        <app-button-spinner></app-button-spinner>
        <span>{{ 'MFA_AUTHENTICATION.CODE_IS_GENERATING' | translate }}</span>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions fxLayoutAlign="flex-end" fxLayoutGap="10px">
    <div>
      <button
        class="button button--primary--state-success"
        *ngIf="!codeValidating"
        [disabled]="form.invalid"
        type="submit">
        {{ 'MFA_AUTHENTICATION.VALIDATE_CODE' | translate }}
      </button>
      <app-button-spinner *ngIf="codeValidating"></app-button-spinner>
    </div>
  </div>
</form>
