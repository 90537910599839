import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHideIfEmpty]',
})
export class HideIfEmptyDirective {

  element = this.el.nativeElement;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    if (this.element.childNodes.length === 0) {
      this.element.style.display = 'none';
    }
  }
}
