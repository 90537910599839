import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Message} from '@features/messages/models/message';
import {SuccessListResponse} from '@store/_common/models/common.types';

@Injectable()
export class AppService {

  constructor(private http: HttpClient) {
  }

  serverTime(): Observable<string> {
    const url = `/api/core/user/Utils/current-instant`;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get<string>(url, { headers, responseType: 'text' as 'json' });
  }

  messageList(): Observable<SuccessListResponse<Message>> {
    const url = `/api/core/user/Messages/messages-list?filter=watched-eq-"false"`;
    return this.http.get<SuccessListResponse<Message>>(url);
  }
}
