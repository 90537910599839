import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface DetailsItem {
  name: string;
  value: any;
  selected?: boolean;
}

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss'],
})
export class DonutComponent implements OnInit {
  width = 225 ;
  height = 250;
  view = [this.width, this.height];
  colorScheme = {
    domain: ['#7CB242', '#4F84CF', '#F0A15C', '#9243B5', '#F26868'],
  };

  filterSetup = {
    dateFrom: {
      key: 'ADMIN.USER.FILTER.FIRSTNAME',
      translationKeyId: 'APP.FILTERS.DATE_FROM',
      fieldName: 'firstName',
      options: {
        isEnabled: true,
        operator: 'contains',
        position: 1,
        isAdvanced: false,
        fieldType: 2,
        defaultValue: null,
        dataType: 1,
        listValues: [],
        listValueEndpoint: null,
      },
      validationRules: [],
    },
    dateTo: {
      key: 'ADMIN.USER.FILTER.FIRSTNAME',
      translationKeyId: 'APP.FILTERS.DATE_TO',
      fieldName: 'firstName',
      options: {
        isEnabled: true,
        operator: 'contains',
        position: 1,
        isAdvanced: false,
        fieldType: 2,
        defaultValue: null,
        dataType: 1,
        listValues: [],
        listValueEndpoint: null,
      },
      validationRules: [],
    },
  };

  details$ = new BehaviorSubject<DetailsItem[]>([]);

  detailsObs$ = this.details$
    .asObservable()
    .pipe(
      map(detailsList => {
        return detailsList.filter(el => el.selected);
      }),
    );

  results = [];

  @Input()
  set data(data: DetailsItem[]) {
    if (!data) {
      return;
    }

    this.details$.next(data.map(el => ({ ...el, selected: true })));
  }

  constructor() {
    if (innerWidth >= 1280) {
      this.view = [innerWidth / 6, 250];
    } else {
      this.view = [225, 250];
    }
  }

  ngOnInit(): void {
    this.detailsObs$.subscribe(details => this.results = details);
  }

  changeItemState(i: number, newState: boolean) {
    const details = this.details$.getValue();

    details[i].selected = newState;

    this.details$.next(details);
  }

  onResize(event) {
    if (event.target.innderWidth < 1280) {
      this.view = [225, 250];
    } else {
      this.view = [event.target.innerWidth / 6, 250];
    }
  }
}
