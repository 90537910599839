import { createReducer, on } from '@ngrx/store';
import { LoadingActions } from './loading.actions';
import produce from 'immer';

export interface LoadingState {
  routes: boolean;
}

const initialState = {
  routes: true,
};

export const loadingReducer = createReducer(
  initialState,
  on(LoadingActions.routeStart, (state) =>
    produce(state, (draftState: LoadingState) => {
      draftState.routes = true;
    }),
  ),
  on(LoadingActions.routeEnd, (state) =>
    produce(state, (draftState: LoadingState) => {
      draftState.routes = false;
    }),
  ),
);
