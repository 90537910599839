import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { forEach } from 'lodash-es';

@Directive({
  selector: '[appNavMenu]',
})
export class NavMenuDirective implements AfterViewInit {

  buttons;
  subMenuItems;

  triggerNodes = [];

  useArrowKeys = true;

  openIndex = null;

  constructor(private el: ElementRef) {

  }

  ngAfterViewInit(): void {
    this.buttons = this.el.nativeElement.querySelectorAll('.navigation__list > li > button');
    this.subMenuItems = this.el.nativeElement.querySelectorAll('.navigation__list > li li');

    forEach(this.subMenuItems, (link) => {
      link.addEventListener('click', () => {
        this.toggleExpand(this.openIndex, false);
      });
    });

    forEach(this.buttons, (el) => {
      this.triggerNodes.push(el);

      const menu = el.parentNode.querySelector('ul');

      el.setAttribute('aria-expanded', 'false');
      this.toggleMenu(menu, false);


      el.addEventListener('click', this.handleButtonClick.bind(this));

      el.addEventListener('keydown', this.handleButtonKeyDown.bind(this));

      if (menu) {
        menu.addEventListener('keydown', this.handleMenuKeyDown.bind(this));
      }
    });

    this.el.nativeElement.addEventListener('focusout', this.handleBlur.bind(this));
  }

  handleButtonClick(event) {
    const button = event.currentTarget;
    const buttonIndex = this.triggerNodes.indexOf(document.activeElement);
    const buttonExpanded = button.getAttribute('aria-expanded') === 'true';

    this.toggleExpand(buttonIndex, !buttonExpanded);
  }

  handleButtonKeyDown(event) {
    const targetButtonIndex = this.triggerNodes.indexOf(document.activeElement);

    if (event.key === 'Escape') {
      this.toggleExpand(this.openIndex, false);
    } else if (this.useArrowKeys && this.openIndex === targetButtonIndex && event.key === 'ArrowDown') {
      event.preventDefault();
      this.triggerNodes[this.openIndex].parentNode.querySelector('ul').querySelector('a').focus();
    } else if (this.useArrowKeys) {
      this.controlFocusByKey(event, this.triggerNodes, targetButtonIndex);
    }
  };

  handleMenuKeyDown(event) {
    if (this.openIndex === null) {
      return;
    }

    const menuLinks = Array.prototype.slice.call(this.triggerNodes[this.openIndex].parentNode.querySelector('ul').querySelectorAll('a'));
    const currentIndex = menuLinks.indexOf(document.activeElement);

    if (event.key === 'Escape') {
      this.triggerNodes[this.openIndex].focus();
      this.toggleExpand(this.openIndex, false);
    } else if (this.useArrowKeys) {
      this.controlFocusByKey(event, menuLinks, currentIndex);
    }
  }

  controlFocusByKey = (keyboardEvent, nodeList, currentIndex) => {
    switch (keyboardEvent.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          const prevIndex = Math.max(0, currentIndex - 1);
          nodeList[prevIndex].focus();
        }
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          const nextIndex = Math.min(nodeList.length - 1, currentIndex + 1);
          nodeList[nextIndex].focus();
        }
        break;
    }
  };

  toggleExpand(index, expanded) {

    if (this.openIndex !== index) {
      this.toggleExpand(this.openIndex, false);
    }

    if (this.triggerNodes[index]) {
      this.openIndex = expanded ? index : null;
      this.triggerNodes[index].setAttribute('aria-expanded', expanded);
      this.toggleMenu(this.triggerNodes[index].parentNode.querySelector('ul'), expanded);
    }
  }

  toggleMenu(domNode, show): void {
    if (domNode) {
      domNode.style.display = show ? 'block' : 'none';
    }
  }

  handleBlur(event): void {
    const menuContainsFocus = this.el.nativeElement.contains(event.relatedTarget);
    if (!menuContainsFocus && this.openIndex !== null) {
      this.toggleExpand(this.openIndex, false);
    }
  }
}
