import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';

@Component({
  selector: 'app-contract-tab-switcher',
  templateUrl: './contract-tab-switcher.component.html',
  styleUrls: ['./contract-tab-switcher.component.scss'],
})
export class ContractTabSwitcherComponent implements OnInit {
  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  constructor(protected readonly store: Store) {}

  ngOnInit(): void {}
}
