import { ObjectUtils } from './object.utils';

export class NumericUtils {

  static isNumeric(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  static toNumber(value: any): number {
    if (ObjectUtils.isEmpty(value)) {
      return null;
    }
    const prepared: string = value.toString().replace(',', '.');
    return parseFloat(prepared);
  }

  static toLocaleFloat(value: any, fraction: number = 2): string {
    return this.toNumber(value).toFixed(fraction).replace('.', ',');
  }

  static round2(n: number): number {
    if (n === undefined || n === null) {
      return n;
    }
    return Math.round((n + 0.00001) * 100) / 100;
  }

  static round4(n: number): number {
    return Math.round((n + 0.0000001) * 10000) / 10000;
  }

  static roundNumber(value: number, precision: number): number {
    const multiplier = this.getMuliplier(precision);
    return Math.round(value * multiplier) / multiplier;
  }

  static netValue(priceAmount: number, quantity: number, round2: boolean = true): number {
    if (NumericUtils.isNumeric(priceAmount) && NumericUtils.isNumeric(quantity)) {
      const ret: number = quantity * priceAmount;
      return (round2 ? this.round2(ret) : ret);
    } else {
      return null;
    }
  }

  static percentValue(netValue: number, percent: number, round2: boolean = true): number {
    if (NumericUtils.isNumeric(netValue) && NumericUtils.isNumeric(percent)) {
      const ret: number = netValue * (percent * 0.01);
      return (round2 ? this.round2(ret) : ret);
    } else {
      return null;
    }
  }

  static grossValue(netValue: number, percent: number, round2: boolean = true): number {
    if (NumericUtils.isNumeric(netValue) && NumericUtils.isNumeric(percent)) {
      const ret: number = netValue * (1 + (percent * 0.01));
      return round2 ? this.round2(ret) : ret;
    } else if (NumericUtils.isNumeric(netValue) && !NumericUtils.isNumeric(percent)) {
      return round2 ? this.round2(netValue) : netValue;
    } else {
      return 0;
    }
  }

  static roundValue(value: number, minDigits: number, maxDigits: number): string {
    if (NumericUtils.isInt(value)) {
      return value.toFixed(minDigits);
    }
    const multiplier: number = NumericUtils.getMuliplier(maxDigits);
    let s = (Math.round(value * multiplier) / multiplier).toFixed(maxDigits);
    let dot = s.lastIndexOf('.');
    if (dot > -1) {
      dot = s.length - dot;
      let maxCut = dot - minDigits - 1;
      while (dot >= 0 && s[s.length - 1] === '0' && maxCut > 0) {
        s = s.substring(0, s.length - 1);
        dot --;
        maxCut --;
      }
    }
    return s;
  }


  private static getMuliplier(digits: number): number {
    const base = 10;
    return Math.pow(base, digits);
  }

  private static isInt(value: number): boolean {
    return value % 1 === 0;
  }

  static isZero(value: any): boolean {
    return value === 0 || ObjectUtils.isEmpty(value);
  }

}
