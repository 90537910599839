export class StringUtils {

  static removeAllSpecial(value: string): string {
    if (value) {
      return value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    }
    return null;
  }

  static removeAllSpecialAndWhitespaces(value: string): string {
    if (value) {
      return value.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g, '');
    }
    return null;
  }

  static insertSeparators(value: string, separator: string): string {
    const lastCharIndex: number = value.length;
    for (let i = lastCharIndex; i > 0; --i) {
      if ((lastCharIndex - i) % 3 === 0 && i !== lastCharIndex) {
        value = value.substring(0, i) + separator + value.substring(i);
      }
    }

    return value;
  }

  static indexOfDecimalPoint(value: string, char: string = '.'): number {
    let lastIntegerIndex: number = value.indexOf(char);
    if (lastIntegerIndex === -1) {
      lastIntegerIndex = value.length;
    }
    return lastIntegerIndex;
  }

  static isEmpty(str: string): boolean {
    return str == null || str === '';
  }

  static isEmptyOrWhitespaces(str: string): boolean {
    if (str == null) {
      return true;
    }

    if (typeof str === 'string') {
      str = str.trim();
    }

    return str === '';
  }

  static trim(str: string): string {
    if (str == null) {
      return null;
    }

    if (typeof str === 'string') {
      str = str.trim();
    }

    return str;
  }

  static nonEmptyTrimmedValueOrNull(str: string): string {
    if (StringUtils.isEmptyOrWhitespaces(str)) {
      return null;
    }
    return StringUtils.trim(str);
  }

}
