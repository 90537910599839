import { ModuleWithProviders, NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { MaterialModule } from './modules/material/material.module';
import { CarouselComponent } from './components/carousel/carousel.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DateService } from './services/date/date.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InputTextComponent } from './components/input-text/input-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { StatusDirective } from '@shared/directives/status.directive';
import { ControlErrorsDirective } from '@shared/validators/control-errors.directive';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { SessionWarnComponent } from '@shared/components/session-warn/session-warn.component';
import { UnauthorizedDialogComponent } from '@shared/components/unauthorized-dialog/unauthorized-dialog.component';
import { MonthPipe } from '@shared/pipes/month.pipe';
import { FullDatePipe } from '@shared/pipes/full-date.pipe';
import { DayOfWeekPipe } from '@shared/pipes/day-of-week.pipe';
import { StickyHeaderComponent } from '@shared/components/sticky-header/sticky-header.component';
import { EmptyListContainerComponent } from '@shared/components/empty-list-container/empty-list-container.component';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { AppTableModule } from '@shared/modules/app-table/app-table.module';
import { ChipComponent } from '@shared/components/chip/chip.component';
import { DonutComponent } from '@shared/components/donut/donut.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { BreadcrumbItemComponent } from '@shared/components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { RouterModule } from '@angular/router';
import { SessionTimerComponent } from '@shared/components/session-timer/session-timer.component';
import { TimeFormatPipe } from '@shared/pipes/time-format.pipe';
import { SortStatusDirective } from './directives/sort-status/sort-status.directive';
import { DateMaskDirective } from '@shared/directives/date-mask/date-mask.directive';
import { AppDatePipe } from '@shared/pipes/date.pipe';
import { ButtonSpinnerComponent } from '@shared/components/button-spinner/button-spinner.component';
import { TableHeaderComponent } from '@shared/components/table-header/table-header.component';
import { LoadersModule } from '@shared/modules/loaders/loaders.module';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { HideMocksDirective } from '@shared/directives/hide-mocks.directive';
import { HasAccessDirective } from '@shared/directives/has-access.directive';
import { HideIfEmptyDirective } from '@shared/directives/hide-if-empty.directive';
import { CardComponent } from '@shared/components/card/card.component';
import { StringifiedArrayIncludesPipe } from '@shared/pipes/stringified-array-contains.pipe';
import { DataBoxComponent } from './components/data-box/data-box.component';
import { ContractTabSwitcherComponent } from '@shared/components/contract-tab-switcher/contract-tab-switcher.component';
import { AppNumberLocalePipe } from '@shared/pipes/number-locale.pipe';
import { DpdDateMaskDirective } from '@shared/directives/date-mask/dpd-date-mask.directive';
import { StartOfDay } from './pipes/start-of-day.pipes';

@NgModule({
    declarations: [
        CarouselComponent,
        ProgressBarComponent,
        AppDatePipe,
        InputTextComponent,
        ConfirmDialogComponent,
        StatusDirective,
        SortStatusDirective,
        ControlErrorsDirective,
        AlertDialogComponent,
        SessionWarnComponent,
        UnauthorizedDialogComponent,
        MonthPipe,
        StartOfDay,
        FullDatePipe,
        DayOfWeekPipe,
        StickyHeaderComponent,
        EmptyListContainerComponent,
        PaginatorComponent,
        ChipComponent,
        DonutComponent,
        BreadcrumbComponent,
        BreadcrumbItemComponent,
        SessionTimerComponent,
        TimeFormatPipe,
        DateMaskDirective,
        DpdDateMaskDirective,
        ButtonSpinnerComponent,
        TableHeaderComponent,
        FormatBytesPipe,
        HideMocksDirective,
        HasAccessDirective,
        HideIfEmptyDirective,
        CardComponent,
        StringifiedArrayIncludesPipe,
        DataBoxComponent,
        ContractTabSwitcherComponent,
        AppNumberLocalePipe,
    ],
    imports: [
        CommonModule,
        NgxChartsModule,
        MaterialModule,
        TranslateModule,
        FlexLayoutModule.withConfig({}),
        FormsModule,
        ReactiveFormsModule,
        NgScrollbarModule,
        NgScrollbarReachedModule,
        AppTableModule,
        RouterModule,
        LoadersModule,
    ],
    exports: [
        CarouselComponent,
        NgxChartsModule,
        TranslateModule,
        ProgressBarComponent,
        AppDatePipe,
        FlexLayoutModule,
        InputTextComponent,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatDialogModule,
        NgScrollbarModule,
        NgScrollbarReachedModule,
        StatusDirective,
        SortStatusDirective,
        ControlErrorsDirective,
        SessionWarnComponent,
        UnauthorizedDialogComponent,
        MonthPipe,
        StartOfDay,
        FullDatePipe,
        DayOfWeekPipe,
        StickyHeaderComponent,
        EmptyListContainerComponent,
        PaginatorComponent,
        ChipComponent,
        DonutComponent,
        BreadcrumbComponent,
        SessionTimerComponent,
        TimeFormatPipe,
        DateMaskDirective,
        DpdDateMaskDirective,
        ButtonSpinnerComponent,
        TableHeaderComponent,
        LoadersModule,
        FormatBytesPipe,
        HideMocksDirective,
        HasAccessDirective,
        HideIfEmptyDirective,
        CardComponent,
        StringifiedArrayIncludesPipe,
        DataBoxComponent,
        ContractTabSwitcherComponent,
        AppNumberLocalePipe,
    ],
    providers: [FormatBytesPipe, DatePipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DateService],
    };
  }
}
