import { createSelector } from '@ngrx/store';
import { appFeatureSelector, AppState } from '../app.reducer';
import { LangState } from './lang.reducer';

const adapterLang = createSelector(
  appFeatureSelector,
  (state: AppState) => state.lang,
);

export const loading = createSelector(
  adapterLang,
  (state: LangState) => state.loading,
);

export const currentLang = createSelector(
  adapterLang,
  (state: LangState) => state.currentLang,
);

export const langSelectors = {
  loading,
  currentLang,
};

