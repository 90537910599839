import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@shared/services/base/base.service';
import { Observable } from 'rxjs';
import { RegulationAcceptPayload } from '@store/regulations/models/regulation-accept-payload';
import { SuccessPayload } from '@store/_common/models/common.types';
import { langSelectors } from '@store/app/lang/lang.selectors';
import { Store } from '@ngrx/store';
import { Customer } from '@features/profile/models/customer';
import {filter, map, switchMap, take} from 'rxjs/operators';
import { UpdateConsentDecisionPayload } from '@store/consents/models/update-consent-decision-payload';
import {initOptionsSelectors} from '@store/app/init-options/init-options.selectors';

@Injectable()
export class ConsentsService extends BaseService {

  currentLang$ = this.store$.select(langSelectors.currentLang);
  consentSource$ = this.store$.select(initOptionsSelectors.consentSource);

  constructor(private http: HttpClient, private store$: Store) {
    super();
  }

  accept(payload: RegulationAcceptPayload): Observable<SuccessPayload<unknown>> {
    const url = `/api/core/user/Regulations/accept`;
    return this.http.put<SuccessPayload<unknown>>(url, payload);
  }

  list(lang = 'pl'): Observable<Customer[]> {
    let url = `/api/core/user/ConsentCampaigns/consents-list/${lang}`;
    // @ts-ignore
    return this.consentSource$.pipe(
      take(1),
      switchMap((consentSource) => {
        if (consentSource === `landingpage`) {
          url = `/api/core/user/ExternalConsentDecisions/consent-decisions/${lang}`;
        }
        return this.http.get<{ customers: Customer[] }>(url).pipe(
          map((customers) => {
            if (customers?.customers) {
              return customers.customers;
            }
            return customers;
          }),
        );
      })
    );
  }

  toDecideList(lang = 'pl'): Observable<Customer[]> {
    const url = `/api/core/user/ConsentCampaigns/to-decide-consents-list/${lang}`;
    return this.http.get<{ customers: Customer[] }>(url).pipe(map((data) => data.customers));
  }

  updateDecision(payload: UpdateConsentDecisionPayload) {
    let url = `/api/core/user/ConsentCampaigns/update-consent-decisions`;
    return this.consentSource$.pipe(
      take(1),
      switchMap((consentSource) => {
        if (consentSource === `landingpage`) {
          url = `/api/core/user/ExternalConsentDecisions/update-consent-decisions`;
        }
        return this.http.put(url, payload);
      })
    );
  }

}
