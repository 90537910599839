import { createSelector } from '@ngrx/store';
import { appFeatureSelector, AppState } from '../app.reducer';


const selector = createSelector(
  appFeatureSelector,
  (state: AppState) => state.serverTime,
);

export const loading = createSelector(
  selector,
  (state) => state.loading,
);

export const success = createSelector(
  selector,
  (state) => state.success,
);

export const data = createSelector(
  selector,
  (state) => state.data,
);

export const errors = createSelector(
  selector,
  (state) => state.errors,
);

export const appServerTimeSelectors = {
  loading,
  errors,
  success,
  data,
};
