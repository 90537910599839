import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessFunctionsSwitcherComponent } from './components/business-functions-switcher/business-functions-switcher.component';
import { BusinessFunctionsPopupComponent } from './components/business-functions-popup/business-functions-popup.component';
import { BusinessFunctionsSwitcherService } from './services/business-functions-switcher.service';

@NgModule({
  declarations: [BusinessFunctionsSwitcherComponent, BusinessFunctionsPopupComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule.forChild()],
  exports: [BusinessFunctionsSwitcherComponent],
  providers: [BusinessFunctionsSwitcherService],
})
export class BusinessFunctionsSwitcherModule {
  static forChild(): ModuleWithProviders<BusinessFunctionsSwitcherModule> {
    return {
      ngModule: BusinessFunctionsSwitcherModule,
    };
  }
}
