import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { initOptionsSelectors } from 'src/app/store/app/init-options/init-options.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  constructor(private store: Store) {
  }
}
