import { createReducer, on } from '@ngrx/store';
import produce, { Draft } from 'immer';
import { HttpErrorResponse } from '@angular/common/http';
import { Language } from './models/lang';
import { langActions } from './lang.actions';

export interface LangState {
  loading?: boolean;
  error?: HttpErrorResponse;
  currentLang?: Language;
}

const initialState: LangState = {
  loading: true,
};

export const langReducer = createReducer(
  initialState,
  on(langActions.changeCurrent, (state, { currentLang }) =>
    produce(state, (draftState: Draft<LangState>) => {
      draftState.loading = true;
      draftState.currentLang = currentLang;
    })
  ),
  on(langActions.changeCurrentLoaded, state =>
    produce(state, (draftState: Draft<LangState>) => {
      draftState.loading = false;
    })
  ),
  on(langActions.setError, (state, { error }) =>
    produce(state, (draftState: Draft<LangState>) => {
      draftState.loading = false;
      draftState.error = error;
    })
  ),
);
