import { Component } from '@angular/core';
import { BaseComponent } from '@shared/abstract/base-component.abstract';
import { BehaviorSubject, Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { MatDialogRef } from '@angular/material/dialog';
import { TimerService } from '@core/services/timer/timer.service';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Component({
  selector: 'app-session-warn',
  templateUrl: './session-warn.component.html',
  styleUrls: [ './session-warn.component.scss' ],
})
export class SessionWarnComponent extends BaseComponent {

  loading$ = new BehaviorSubject<boolean>(false);
  sessionExpiryTime$: Observable<number>;


  constructor(
    private keycloakService: KeycloakService,
    private dialogRef: MatDialogRef<SessionWarnComponent>,
    private timerService: TimerService,
    private tenantConfigService: TenantConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sessionExpiryTime$ = this.timerService.currentSessionTime;
    this.dialogRef.disableClose = true;
  }

  async extendSession() {
    try {
      this.loading$.next(true);
      const response = await this.keycloakService.updateToken(180);
      if (response) {
        this.loading$.next(false);
        this.timerService.resetSession();
        this.dialogRef.close(response);
      }
    } catch (e) {
      sessionStorage.clear();
      this.keycloakService.logout(this.tenantConfigService.appBaseUrl);
    }
  }

}
