import { createAction, props } from '@ngrx/store';
import { ChangeParamsPayload, FailPayload, SuccessListResponse } from '@store/_common/models/common.types';
import { Customer } from '@features/profile/models/customer';

const loading = createAction('[Consents] To decide consent list loading');
const failed = createAction('[Consents] To decide consent list failed', props<FailPayload>());
const loaded = createAction('[Consents] To decide consent list success', props<SuccessListResponse<Customer>>());
const updateParams = createAction('[Consents] To decide consent list update params', props<ChangeParamsPayload>());
const clearParams = createAction('[Consents] To decide consent list clear params');
const failedFiltersLoad = createAction('[Settlements] Load filters failed', props<FailPayload>());
const loadedFilters = createAction('[Settlements] Load filters success');

export const toDecideConsentListActions = {
  failed,
  loading,
  loaded,
  updateParams,
  clearParams,
  failedFiltersLoad,
  loadedFilters,
};
