import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { select, Store } from '@ngrx/store';
import { appServerTimeSelectors } from '@store/app/server-time/server-time.selectors';
import * as moment from 'moment';
import { BaseComponent } from '@shared/abstract/base-component.abstract';
import { first, map, startWith, switchMap, take } from 'rxjs/operators';
import { Moment } from 'moment';
import { Observable, of, Subject, timer } from 'rxjs';
import { selectLoading } from '@store/app/loading/loading.selectors';
import { TimerService } from '@core/services/timer/timer.service';

@Component({
  selector: 'app-session-timer',
  templateUrl: './session-timer.component.html',
  styleUrls: ['./session-timer.component.scss'],
})
export class SessionTimerComponent extends BaseComponent {

  serverTime: Moment;

  serverTime$ = this.store$.pipe(select(appServerTimeSelectors.data));
  routesLoading$ = this.store$.select(selectLoading);
  timer$: Observable<number>;

  constructor(private keycloakService: KeycloakService, private store$: Store, private sessionTimeService: TimerService) {
    super();
  }

  ngOnInit(): void {
    this.timer$ = this.sessionTimeService.currentSessionTime;

    /** Refresh ServerTime */
    timer(0, 1000).subscribe(() => {
      this.watchServerTime();
    });
  }

  handleReset(): void {
    this.sessionTimeService.resetSession();
  }

  private async watchServerTime() {
    const time: string = await this.serverTime$.pipe(first()).toPromise(); // todo refactor
    if (this.serverTime) {
      this.serverTime = moment(this.serverTime).add(1, 'second');
    } else {
      this.serverTime = moment(time);
    }
  }
}

