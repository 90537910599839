import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription, timer } from 'rxjs';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import { selectLoading } from '@store/app/loading/loading.selectors';
import { AppState } from '@store/app/app.reducer';
import { initOptionsActions } from '@store/app/init-options/init-options.actions';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FirstLoginDialogComponent } from '@core/components/first-login-dialog/first-login-dialog.component';
import { BaseComponent } from '@shared/abstract/base-component.abstract';
import { Permission } from '@core/enums/permission.enum';
import { consentListSelectors } from '@store/consents/selectors/to-decide-consent-list.selectors';
import { ToDecideConsentsDialogComponent } from '@core/components/to-decide-consents-dialog/to-decide-consents-dialog.component';
import { TimerService } from '@core/services/timer/timer.service';
import { SessionWarnComponent } from '@shared/components/session-warn/session-warn.component';

@Component({
  selector: 'app-logged-in-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrls: ['./logged-in-layout.component.scss'],
})
export class LoggedInLayoutComponent extends BaseComponent {
  settingsLoading$ = this.store.select(initOptionsSelectors.loading);
  routesLoading$ = this.store.select(selectLoading);
  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  isUserAccepted: boolean;
  sessionWarnTimeSubscription: Subscription;

  toDecideConsentList$ = this.store.pipe(select(consentListSelectors.consentList));

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private keycloakService: KeycloakService,
    private dialog: MatDialog,
    private sessionTimeService: TimerService
  ) {
    super();
    this.isUserAccepted = this.keycloakService.isUserInRole(Permission.USER_ACCEPTED);
  }

  ngOnInit(): void {
    this.store.dispatch(initOptionsActions.loadInitOptions());

    /** Refresh AccessToken every 1min */
    timer(60000, 60000).subscribe(() => {
      this.keycloakService.updateToken(50);
    });

    if (this.router.url !== '/no-permissions') {
      this.settingsLoading$
        .pipe(
          filter((loading: boolean) => !loading && !this.isUserAccepted),
          switchMap(() =>
            this.dialog
              .open(FirstLoginDialogComponent, {
                width: '700px',
                disableClose: true,
                panelClass: ['app-dialog', 'backgroundDark'],
              })
              .afterClosed()
          ),
          switchMap(() => this.toDecideConsentList$),
          filter((data) => !!data && (data || []).length > 0), // check if  PERM_PROFILE_CONSENTS_EDIT
          switchMap((data) =>
            this.dialog
              .open(ToDecideConsentsDialogComponent, {
                data,
                width: '700px',
                panelClass: ['app-dialog', 'backgroundDark'],
              })
              .afterClosed()
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe();
    }

    combineLatest([this.settingsLoading$, this.toDecideConsentList$])
      .pipe(
        filter(([loading, data]) => !loading && this.isUserAccepted && !!data && (data || []).length > 0),
        takeUntil(this.destroyed$)
      )
      .subscribe(([, data]) => {
        this.dialog.open(ToDecideConsentsDialogComponent, {
          data,
          width: '700px',
          panelClass: ['app-dialog', 'backgroundDark'],
        });
      });
  }

  private listenForSessionWarnTime(): void {
    this.store.select(initOptionsSelectors.loadingInitOptions).pipe(
      filter((v) => v === false),
      tap(() => {
        if (!this.sessionWarnTimeSubscription) {
          this.sessionWarnTimeSubscription = this.sessionTimeService.warnTime
            .pipe(distinctUntilChanged(), filter(Boolean))
            .subscribe(() => this.dialog.open(SessionWarnComponent));
        }
      })
    );
  }
}
