import { createAction, props } from '@ngrx/store';
import {ChangeParamsPayload, FailPayload, SuccessListResponse} from '@store/_common/models/common.types';
import {Message} from '@features/messages/models/message';

const loading = createAction('[App unread messages] Load list');
const failed = createAction('[App unread messages] Load list failed', props<FailPayload>());
const loaded = createAction('[App unread messages] Load list success', props<SuccessListResponse<Message>>());
const updateParams = createAction('[App unread messages] Load list update params', props<ChangeParamsPayload>());
const clearParams = createAction('[App unread messages] Load list clear params');
const failedFiltersLoad = createAction('[Settlements] Load filters failed', props<FailPayload>());
const loadedFilters = createAction('[Settlements] Load filters success');

export const appUnreadMessagesActions = {
  failed,
  loading,
  loaded,
  updateParams,
  clearParams,
  failedFiltersLoad,
  loadedFilters,
};
