import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-first-login-alert-dialog',
  templateUrl: './first-login-alert-dialog.component.html',
  styleUrls: [ './first-login-alert-dialog.component.scss' ],
})
export class FirstLoginAlertDialogComponent {

  constructor(private keycloakService: KeycloakService) {
  }

  onLogout(): void {
    this.keycloakService.logout();
  }
}
