import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppStoreModule as AppStore } from './app/app-store.module';
import { RegulationsStoreModule } from '@store/regulations/regulations-store.module';
import { extModules } from '../build-specifics';
import { ConsentsStoreModule } from '@store/consents/consents-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}, {
      metaReducers: [],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    extModules,
    AppStore,
    RegulationsStoreModule,
    ConsentsStoreModule,
  ],
})
export class AppStoreModule {
}
