import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { langSelectors } from '@store/app/lang/lang.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'fullDate',
})
export class FullDatePipe implements PipeTransform {

  constructor(private store$: Store) {
  }

  transform(value: Date): Observable<string> {
    return this.store$.pipe(
      select(langSelectors.currentLang),
      map((lang: string) => `${value.getDate()} ${value.toLocaleDateString(lang, { month: 'long' })} ${value.getFullYear()}`));
  }
}
