<div class="card">
  <div appHideIfEmpty class="card__top">
    <ng-content select="[top]"></ng-content>
  </div>

  <div appHideIfEmpty class="card__header">
    <ng-container *ngIf="cancelButton">
      <button class="card__back-button" [routerLink]="cancelLink">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </ng-container>
    <ng-content select="[header]"></ng-content>
  </div>

  <div class="card__content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
