import { MenuItem } from '@core/models/menu-item';
import { Permission } from '@core/enums/permission.enum';
import { intersection } from 'lodash-es';

export interface NavigationMenuConfig {
  mocks: boolean;
  permissions: string[];
}

export class NavigationUtil {
  static getMenuItems(scaniaIcons = false): MenuItem[] {
    return [
      {
        name: 'APP.NAVIGATION.DASHBOARD',
        icon: scaniaIcons ? 'scania-dashboard' : 'menu-dashboard',
        path: '/dashboard',
        mock: false,
        permissions: [Permission.PERM_BASE_DASHBOARD],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.SERVICES',
        icon: scaniaIcons ? 'scania-services' : 'menu-services-2',
        path: '/services',
        mock: false,
        permissions: [Permission.PERM_SVC],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.SETTLEMENTS',
        icon: scaniaIcons ? 'l_menu-settlements' : 'menu-settlements',
        path: '/settlements',
        mock: false,
        permissions: [Permission.PERM_DOCUMENTS_INVOICES],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.PAYMENTS',
        icon: scaniaIcons ? 'l_menu-payments' : 'menu-payments',
        path: '/payments',
        mock: false,
        permissions: [Permission.PERM_PAYMENTS],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.DOCUMENTS',
        icon: scaniaIcons ? 'scania-documents' : 'menu-documents',
        path: '/documents',
        mock: false,
        permissions: [Permission.PERM_DOCUMENTS_CONTRACTS],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.REPOSITORY',
        icon: scaniaIcons ? 'scania-repository' : 'submenu-doc-agreements',
        path: '/repository',
        mock: false,
        permissions: [Permission.PERM_REPOSITORY],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.PROFILE',
        icon: scaniaIcons ? 'scania-profile' : 'menu-profile',
        path: '/profile',
        mock: false,
        permissions: [Permission.PERM_PROFILE],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.MESSAGES',
        icon: scaniaIcons ? 'scania-messages' : 'submenu-messages',
        path: '/messages',
        mock: false,
        permissions: [Permission.PERM_MESSAGES],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.REPORTS',
        icon: scaniaIcons ? 'scania-reports' : 'menu-reports',
        path: '/reports',
        mock: false,
        permissions: [Permission.PERM_REPORT, Permission.PERM_REPORT_TURNOVER],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.CONTACT',
        icon: scaniaIcons ? 'scania-contact' : 'menu-contact',
        path: '/contact',
        mock: false,
        permissions: [Permission.PERM_CONTACT],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.SUPPORT',
        icon: scaniaIcons ? 'l_submenu-servicedesk' : 'submenu-servicedesk',
        path: '/service-desk',
        mock: false,
        permissions: [Permission.PERM_SERVICE],
        children: [],
      },
      {
        name: 'APP.NAVIGATION.KNOWLEDGE_BASE',
        icon: scaniaIcons ? 'l_submenu-faq' : 'submenu-faq',
        path: '/knowledge-base',
        mock: false,
        permissions: [Permission.PERM_KNOWLEDGEBASE],
        children: [],
      },
    ];
  }

  static getFilteredMenuItems(mocks = false, permissions = [], scaniaIcons = false): MenuItem[] {
    const data = NavigationUtil.getMenuItems(scaniaIcons)
      .filter((item: MenuItem) => {
        if (mocks) {
          permissions = [...permissions, Permission.PERM_MOCKS];
        }

        return item?.permissions ? intersection(item.permissions, permissions).length !== 0 : true;
      })
      .map((item: MenuItem) => {
        return {
          ...item,
          children: (item.children || []).filter((childItem: MenuItem) => {
            if (mocks) {
              permissions = [...permissions, Permission.PERM_MOCKS];
            }

            return childItem?.permissions ? intersection(childItem.permissions, permissions).length !== 0 : true;
          }),
        };
      });

    if (mocks) {
      return data;
    } else {
      return data
        .filter((item: MenuItem) => !item.mock)
        .map((item: MenuItem) => ({
          ...item,
          children: item.children.filter((child: MenuItem) => !child.mock),
        }));
    }
  }
}
