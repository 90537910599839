import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogConfig } from '@shared/components/confirm-dialog/confirm-dialog-config';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ]
})
export class ConfirmDialogComponent implements OnDestroy {

  get content(): string {
    return this.dialogData && this.dialogData.contentText || 'CONFIRMATION_TEXT';
  }

  get submitText(): string {
    return this.dialogData && this.dialogData.submitText || 'APP.GENERAL.YES';
  }

  get successContent(): string {
    return this.dialogData && this.dialogData.successText || 'CONFIRMATION_TEXT';
  }

  get contentComponent() {
    return this.dialogData && this.dialogData.contentComponent;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogConfig,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
  }

  ngOnDestroy(): void {
    this.dialogData.success$.pipe(
      first()
    ).subscribe((res: boolean) => {
      this.dialogRef.close(res);
    });
  }
}
