import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ConsentsService } from '@store/consents/consents.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { consentListActions } from '@store/consents/actions/consent-list.actions';
import { ConsentCampaign } from '@store/consents/models/consent-campaign';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Customer } from '@features/profile/models/customer';
import { updateConsentDecisionActions } from '@store/consents/actions/update-consent-decision.actions';
import { toDecideConsentListActions } from '@store/consents/actions/to-decide-consent-list.actions';


@Injectable()
export class ConsentsEffects {
  constructor(
    private actions$: Actions,
    private service: ConsentsService,
    private store$: Store,
  ) {
  }

  
  getConsentList$ = createEffect(() => this.actions$
    .pipe(
      ofType(consentListActions.loading),
      switchMap(() => this.service.list()
        .pipe(
          map((result: Customer[]) => consentListActions.loaded({ records: result, totalRecords: result?.length })),
          catchError((errors: HttpErrorResponse) => of(consentListActions.failed({ errors }))),
        ),
      ),
    ));


  
  updateConsentDecision$ = createEffect(() => this.actions$.pipe(
    ofType(updateConsentDecisionActions.loading),
    switchMap(({ data }) =>
      this.service.updateDecision(data).pipe(
        map((response: any) => updateConsentDecisionActions.loaded({ response })),
        catchError((errors) => of(updateConsentDecisionActions.failed(errors))),
      ),
    ),
  ));

  
  getToDecideConsentList$ = createEffect(() => this.actions$
    .pipe(
      ofType(toDecideConsentListActions.loading),
      switchMap(() => this.service.toDecideList()
        .pipe(
          map((result: Customer[]) => toDecideConsentListActions.loaded({ records: result, totalRecords: result?.length })),
          catchError((errors: HttpErrorResponse) => of(toDecideConsentListActions.failed({ errors }))),
        ),
      ),
    ));

}
