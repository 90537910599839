import { createSelector } from '@ngrx/store';
import { consentsFeatureSelector, ConsentsState } from '@store/consents/consents.reducer';
import { Customer } from '@features/profile/models/customer';


const selector = createSelector(
  consentsFeatureSelector,
  (state: ConsentsState) => state.toDecideConsentList,
);

export const loading = createSelector(
  selector,
  (state) => state.loading,
);

export const list = createSelector(
  selector,
  (state) => state.records,
);

export const params = createSelector(
  selector,
  (state) => state.params || {},
);

export const totalResults = createSelector(
  selector,
  (state) => state.totalRecords,
);

export const consentList = createSelector(
  list,
  (data: Customer[]) => (data || []).reduce((prev, curr) => ([
    ...prev,
    ...curr.consentCampaigns.map((item) => ({ ...item, customer: { id: curr.id, name: curr.name } })),
  ]), []),
);


export const consentListSelectors = {
  loading,
  list,
  totalResults,
  consentList,
  params,
};
