import * as moment from 'moment';

export class ObjectUtils {

  static clone(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  static isArray(value: any): value is Array<any> {
    return value && Array.isArray(value);
  }

  static isObject(value: any): value is object {
    return value && typeof value === 'object';
  }

  static isNull(value: any): value is null {
    return value == null;
  }

  static isEmpty(value) {
    return value == null || value === '';
  }

  static isFunction<T>(value: any): value is T {
    return value && typeof value === 'function';
  }

  static isString(value: any): value is string {
    return value && typeof value === 'string';
  }

  static isNumber(value): value is number {
    return value && typeof value === 'number';
  }

  static isDate(value: any): boolean {
    return value && (moment.isDate(value) || moment.isMoment(value));
  }

  static isBoolean(value: any): value is boolean {
    return typeof value === 'boolean';
  }

  static boolToString(value: boolean | null): string | null {
    return value != null ? `${value}` : null;
  }

  static numberToString(value: number | null): string | null {
    return value != null ? `${value}` : null;
  }

  static endpointId(endpointIdSchemeId: string, endpointIdValue: string): string {
    return `${endpointIdSchemeId}~${endpointIdValue}`;
  }

  static deleteEmptyFields<T>(obj: T): Partial<T> {
    Object.keys(obj).forEach((key) => (ObjectUtils.isEmpty(obj[key])) && delete obj[key]);
    return obj;
  }

  static allPropertiesEmpty<T>(obj: T): boolean {
    if (!this.isObject(obj) && !this.isArray(obj) && this.isEmpty(obj)) {
      return true;
    } else if (this.isArray(obj)) {
      return this.isArrayEmpty(obj);
    } else if (ObjectUtils.isObject(obj)) {
      return Object.keys(obj).reduce((acc, val) => {
        const empty = this.allPropertiesEmpty(obj[val]);

        return acc && empty;
      }, true);
    }
    return false;
  }

  static isArrayEmpty(array: Array<any>): boolean {
    if (!array) {
      return true;
    } else {
      return array.reduce((acc, curr) => {
        if (this.isObject(curr)) {
          return this.allPropertiesEmpty(curr) && acc;
        }
        return this.isEmpty(curr) && acc;
      }, true);
    }
  }

  static replaceEmptyStringsWithNulls(object: any): any {
    if (ObjectUtils.isEmpty(object)) {
      return null;
    } else if (ObjectUtils.isArray(object)) {
      object = object.map(el => this.replaceEmptyStringsWithNulls(el));
    } else if (ObjectUtils.isObject(object)) {
      const objWithNulls = {};
      Object.entries(object).forEach(([key, value]) => {
        objWithNulls[key] = this.replaceEmptyStringsWithNulls(value);
      });
      return objWithNulls;
    }

    return object;
  }

  static isObjectEmpty(obj): boolean {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  /**
   * Spłaszcza obiekt, czyli puste stringi oraz puste obiekty zamieniane są na null
   * @param value
   */
  static flattenEmptyValues<T>(value: T): T {
    if (this.isArray(value)) {
      let allPropertiesAreNullOrEmpty = true;
      for (let i = 0; i < value.length; i++) {
        const newPropertyValue = this.flattenEmptyValues(value[i]);
        value[i] = newPropertyValue;
        allPropertiesAreNullOrEmpty = allPropertiesAreNullOrEmpty && this.isEmpty(newPropertyValue);
      }
      return allPropertiesAreNullOrEmpty ? null : value;
    } else if (this.isObject(value)) {
      let allPropertiesAreNullOrEmpty = true;
      for (const [property, propertyValue] of Object.entries(value)) {
        const newPropertyValue = this.flattenEmptyValues(propertyValue);
        value[property] = newPropertyValue;
        allPropertiesAreNullOrEmpty = allPropertiesAreNullOrEmpty && this.isEmpty(newPropertyValue);
      }
      return allPropertiesAreNullOrEmpty ? null : value;
    } else if (this.isEmpty(value)) {
      return null;
    } else {
      return value;
    }
  }

 static groupBy(array, key) {
    return array.reduce((objectsByKeyValue, obj) => {
      const val = obj[key];
      objectsByKeyValue[val] = (objectsByKeyValue[val] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
 }

}
