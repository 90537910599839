import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { get } from 'lodash-es';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: [ './alert-dialog.component.scss' ],
})
export class AlertDialogComponent {

  get title(): string {
    return get(this.dialogData, 'title', 'Dane zostaną utracone jesli ich nie zapiszesz.');
  }

  get confirmation(): boolean {
    return get(this.dialogData, 'confirmation', true);
  }

  constructor(private dialogRef: MatDialogRef<AlertDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData) {
  }

  onClose(): void {
    this.dialogRef.close(true);
  }
}
