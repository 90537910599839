import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { langSelectors } from '@store/app/lang/lang.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {

  constructor(private store$: Store) {
  }

  transform(value: Date): Observable<string> {
    return this.store$.pipe(
      select(langSelectors.currentLang),
      map(lang => this.capitalizeFirstLetter(value.toLocaleDateString(lang, { weekday: 'long' }))));
  }

  private capitalizeFirstLetter(val: string): string {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
}
