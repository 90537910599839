import { OnInit, OnDestroy, Directive } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class BaseComponent implements OnInit, OnDestroy {
  protected loading$: Observable<boolean>;
  protected destroyed$ = new Subject<boolean>();

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
