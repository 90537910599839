import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { TenantConfigService } from '@core/services/tenant-config.service';
import {NavigationEnd, Router} from '@angular/router';
import { MenuService } from '@core/services/menu/menu.service';
import { langActions } from '@store/app/lang/lang.actions';
import { NavigationUtil } from '@core/utils/navigation.util';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import { langSelectors } from '@store/app/lang/lang.selectors';
import { MatSelect } from '@angular/material/select';
import {appUnreadMessagesSelectors} from '@store/app/unread-messages/unread-messages.selectors';
import {appUnreadMessagesActions} from '@store/app/unread-messages/unread-messages.actions';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {environment} from '@environment/environment';

@Component({
  selector: 'app-header-scania',
  templateUrl: './header-scania.component.html',
  styleUrls: [ './header-scania.component.scss' ]
})
export class HeaderScaniaComponent implements OnInit {

  profileData;
  isMobileOpen = false;
  isLanguageSelectOpen = false;
  menu = NavigationUtil.getFilteredMenuItems(this.tenantConfigService.mocks, this.keycloakService.getUserRoles(), true);
  baseRoute = this.menuService.baseRoute;

  locationOptions$ = this.store.select(initOptionsSelectors.locationOptions);
  unreadMessagesTotalResults$ = this.store.select(appUnreadMessagesSelectors.totalResults);
  currentLang$ = this.store.select(langSelectors.currentLang);

  @ViewChild('languageSelect') languageSelect: MatSelect;

  constructor(
    private store: Store,
    private keycloakService: KeycloakService,
    private tenantConfigService: TenantConfigService,
    private router: Router,
    private menuService: MenuService
  ) {
    this.profileData = this.keycloakService.getKeycloakInstance().tokenParsed;
    this.refreshUnreadMessages();
  }

  changeLang(lang): void {
    this.store.dispatch(langActions.changeCurrent({ currentLang: lang }));
  }

  toggleMenu(): void {
    this.isMobileOpen = !this.isMobileOpen;
  }

  logout(): void {
    sessionStorage.clear();
    this.keycloakService.logout(this.tenantConfigService.appBaseUrl);
  }

  onLanguageSelectOpenedChange(isOpen: boolean): void {
    this.isLanguageSelectOpen = isOpen;
  }

  onLanguageSelectClick(): void {
    if (this.isLanguageSelectOpen) {
      this.languageSelect.close();
      this.isLanguageSelectOpen = false;
    } else {
      this.languageSelect.open();
      this.isLanguageSelectOpen = true;
    }
  }

  ngOnInit(): void {
  }

  cMobileMenu(): void {
    this.isMobileOpen = false;
  }

  

  get isBusinessSwitcherAvailable(): boolean {
    if (environment.applicationLauncher?.type === 'APP_LIST') {
      return true;
    }
    return false;
  }

  private refreshUnreadMessages(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      debounceTime(2000),
      tap((event) => {
        if (event instanceof NavigationEnd) {
          this.store.dispatch(appUnreadMessagesActions.loading());
        }
      })).subscribe();
  }
}
