import { createAction, props } from '@ngrx/store';
import { InitOptions } from './models/init-options.model';
import { HttpErrorResponse } from '@angular/common/http';

const loadInitOptions = createAction('[App Settings] Started loading settings');
const setInitOptions = createAction('[App Settings] Set loaded settings', props<{ options: InitOptions }>());
const loadInitOptionsError = createAction('[App Settings] Error when loading settings', props<{ error: HttpErrorResponse }>());
const mfaDialogClosed = createAction('[App Settings] Mfa dialog closed');

export const initOptionsActions = {
  loadInitOptions,
  setInitOptions,
  loadInitOptionsError,
  mfaDialogClosed
};
