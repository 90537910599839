import { Component, OnInit } from '@angular/core';
import { MenuService } from '@core/services/menu/menu.service';

@Component({
  selector: 'app-footer-scania',
  templateUrl: './footer-scania.component.html',
  styleUrls: [ './footer-scania.component.scss' ]
})
export class FooterScaniaComponent implements OnInit {

  baseRoute = this.menuService.baseRoute;

  constructor(private menuService: MenuService) {
  }

  ngOnInit(): void {
  }

}
