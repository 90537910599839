import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Language } from './models/lang';

const changeCurrent = createAction('[App Langs] Change current lang loading', props<{ currentLang: Language }>());
const changeCurrentLoaded = createAction('[App Langs] Change current loaded');
const setError = createAction('[App Langs] Error when loading langs', props<{ error: HttpErrorResponse, currentLang: Language }>());

export const langActions = {
  changeCurrent,
  changeCurrentLoaded,
  setError,
};
