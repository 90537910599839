<header [ngClass]="{'mobile-open': isMobileOpen}" class="header">
  <div class="navigation">

    <div class="navigation__top">
      <div class="navigation__logo-wrapper">
        <a [routerLink]="baseRoute">
          <img src="assets/images/logo_scania.png"/>
        </a>
      </div>

      <div class="navigation__user-menu navigation__top-item"
           #trigger="matMenuTrigger"
           [matMenuTriggerFor]="userMenu"
           [class.navigation__user-menu--active]="trigger.menuOpen">
        <mat-icon class="make-white" svgIcon="scania-profile"></mat-icon>
        <mat-icon>arrow_drop_down</mat-icon>
      </div>

      <div class="navigation__business-functions-switcher navigation__top-item" *ngIf="isBusinessSwitcherAvailable">
        <app-business-functions-switcher>
        </app-business-functions-switcher>
      </div>

      <div class="navigation__language-switcher navigation__top-item" (click)="onLanguageSelectClick()"
           [class.navigation__language-switcher--active]="isLanguageSelectOpen">
        <mat-select (valueChange)="changeLang($event)" #languageSelect
                    (openedChange)="onLanguageSelectOpenedChange($event)"
                    *ngIf="( locationOptions$ | async )?.langList?.length"
                    [value]="( currentLang$ | async )">
          <mat-select-trigger>
            {{ 'LANGUAGE.' + (currentLang$ | async) | translate }}
          </mat-select-trigger>
          <mat-option *ngFor="let lang of (locationOptions$ | async)?.langList" [value]="lang">
            {{ 'LANGUAGE.' + lang | translate }}
          </mat-option>
        </mat-select>
      </div>

      <div class="navigation__hamburger navigation__top-item" (click)="toggleMenu()">
        <div>
          <mat-icon *ngIf="!isMobileOpen">dehaze</mat-icon>
          <mat-icon *ngIf="isMobileOpen">clear</mat-icon>
        </div>
      </div>

      <div class="navigation__icon-wrapper navigation__top-item">
        <a [routerLink]="baseRoute">
          <img src="assets/images/scania-symbol.png"/>
        </a>
      </div>
    </div>

    <div class="navigation__header">
      <nav class="content">
        <ul appNavMenu class="navigation__list">
          <li *ngFor="let item of menu" class="navigation__item group">
            <ng-container *ngIf="item?.children?.length; else dashboard">
              <button [routerLink]='item.path' class="group__title" disableLink
                      routerLinkActive="group__title--active">
                <mat-icon class="make-white" [svgIcon]="item.icon"></mat-icon>
                <span>{{ item.name | translate }}</span>
              </button>
              <ul class="group__list">
                <div class="group__list-content">
                  <li *ngFor="let child of item.children">
                    <a [routerLink]='child.path'
                       class="group__list-item"
                       routerLinkActive="group__list-item--active">
                    <mat-icon class="make-white" [svgIcon]="child.icon"></mat-icon>
                    <span>{{ child.name | translate }}</span>
                  </a></li>
                </div>
              </ul>
            </ng-container>
            <ng-template #dashboard>
              <button [routerLink]='item.path' class="group__title" role="link"
                      routerLinkActive="group__title--active" (click)="cMobileMenu()">

                <mat-icon *ngIf="item.name === 'APP.NAVIGATION.MESSAGES'; else normalIcon"
                          [svgIcon]="item.icon"
                          [matBadge]="(unreadMessagesTotalResults$ | async) || null"
                          matBadgeColor="warn"
                          matBadgePosition="below">
                </mat-icon>
                <ng-template #normalIcon>
                  <mat-icon class="make-white" [svgIcon]="item.icon"></mat-icon>
                </ng-template>

                <span>{{ item.name | translate }}</span>
              </button>
            </ng-template>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>

<mat-menu #userMenu="matMenu">
  <div class="user-menu__user-data" mat-menu-item (click)="$event.stopPropagation()">
    <p class="user-menu__user-name">{{profileData.name}}</p>
    <p class="user-menu__user-email">{{profileData.email}}</p>
  </div>

  <mat-divider></mat-divider>

  <button mat-menu-item (click)="logout()">{{ 'APP.LOGOUT' | translate }}</button>
</mat-menu>
