import { AfterContentInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export enum ErrorCodeEnum {
  required = 'required',
  minlength = 'minlength',
  maxlength = 'maxlength',
  email = 'email',
  matDatepickerMin = 'matDatepickerMin',
  matDatepickerMax = 'matDatepickerMax',
  matDatepickerParse = 'matDatepickerParse',

  // Custom validations
  letter = 'letter',
  number = 'number',
  phone = 'phone',
  maxDate = 'maxDate',
  minDate = 'minDate'
}

export const ErrorCodeMessage = {
  [ErrorCodeEnum.required]: 'APP.VALIDATION.REQUIRED',
  [ErrorCodeEnum.email]: 'APP.VALIDATION.EMAIL',
  [ErrorCodeEnum.maxlength]: 'APP.VALIDATION.MAX_LENGTH',
  [ErrorCodeEnum.minlength]: 'APP.VALIDATION.MIN_LENGTH',
  [ErrorCodeEnum.letter]: 'APP.VALIDATION.LETTERS',
  [ErrorCodeEnum.phone]: 'APP.VALIDATION.PHONE',
  [ErrorCodeEnum.matDatepickerMin]: 'APP.VALIDATION.MIN_DATE',
  [ErrorCodeEnum.matDatepickerMax]: 'APP.VALIDATION.MAX_DATE',
  [ErrorCodeEnum.matDatepickerParse]: 'Niepoprawny format daty',
  [ErrorCodeEnum.number]: 'Tylko cyfry',
  [ErrorCodeEnum.maxDate]: 'Data od musi być mniejsza od daty do',
  [ErrorCodeEnum.minDate]: 'Data do musi być większa od daty od',
};

@Directive({
  selector: '[appControlErrors]',
})
export class ControlErrorsDirective implements OnDestroy, AfterContentInit {

  @Input() appControlErrors: UntypedFormControl;

  @Input() customErrorMessages: {[key: string]: string};

  private readonly errorCodes: string[];

  private destroyed$: Subject<boolean>;

  constructor(private el: ElementRef, private translateService: TranslateService) {
    this.destroyed$ = new Subject();

    // Change priority checking here
    this.errorCodes = [
      ErrorCodeEnum.required,
      ErrorCodeEnum.minlength,
      ErrorCodeEnum.maxlength,
      ErrorCodeEnum.maxDate,
      ErrorCodeEnum.minDate,
      ErrorCodeEnum.matDatepickerMin,
      ErrorCodeEnum.matDatepickerMax,
      ErrorCodeEnum.matDatepickerParse,
      ErrorCodeEnum.email,
      ErrorCodeEnum.letter,
      ErrorCodeEnum.number,
      ErrorCodeEnum.phone,
    ];
  }

  ngAfterContentInit(): void {
    this.checkValidity();
    this.appControlErrors.statusChanges.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(() => this.checkValidity());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private checkValidity(): void {
    let message = null;

    for (const errorCode of this.errorCodes) {
      if (this.customErrorMessages?.[errorCode]) {
        message = this.translate(this.customErrorMessages[errorCode]);
        break;
      }
      if (this.appControlErrors.hasError(errorCode)) {
        message = this.getMessageByErrorCode(errorCode);
        break;
      }
    }
    this.el.nativeElement.innerHTML = message;
  }

  private getMessageByErrorCode(errorCode: string): string {
    switch (errorCode) {
      case ErrorCodeEnum.minlength:
        return this.translate(ErrorCodeMessage[ErrorCodeEnum.minlength], this.appControlErrors.getError(errorCode).requiredLength);
      case ErrorCodeEnum.maxlength:
        return this.translate(ErrorCodeMessage[ErrorCodeEnum.maxlength], this.appControlErrors.getError(errorCode).requiredLength);
      default:
        return this.translate(ErrorCodeMessage[errorCode]);
    }
  }

  private translate(key: string, prop?): string {
    if (prop) {
      return this.translateService.instant(key, { prop });
    } else {
      return this.translateService.instant(key);
    }
  }
}
