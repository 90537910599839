import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';

@Component({
  selector: 'app-footer-default',
  templateUrl: './footer-default.component.html',
  styleUrls: ['./footer-default.component.scss'],
})
export class FooterDefaultComponent implements OnInit {
  currDate = new Date();
  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  constructor(private readonly store: Store) {}

  ngOnInit(): void {}
}
