import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppStoreModule } from '../store/app-store.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from '@core/components/footer/footer.component';
import { SnackbarService } from './services/snackbar/snackbar.service';
import { DialogService } from './services/dialog/dialog.service';
import { HttpErrorService } from './services/http-error/http-error.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { LoggedInLayoutComponent } from './components/logged-in-layout/logged-in-layout.component';
import { SharedModule } from '@shared/shared.module';
import { KeycloakAngularModule } from 'keycloak-angular';
import { KeycloakInitializer, TenantRealm } from '@core/utils';
import { HTTP_INTERCEPTORS, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FirstLoginAlertDialogComponent } from '@core/components/first-login-alert-dialog/first-login-alert-dialog.component';
import { FirstLoginDialogComponent } from '@core/components/first-login-dialog/first-login-dialog.component';
import { IconsService } from '@core/services/icon/icons.service';
import { DisableRouterLinkDirective } from './directives/disable-router-link.directive';
import { NavMenuDirective } from '@core/directives/nav-menu.directive';
import { RequestInterceptor } from '@core/interceptors/request.interceptor';
import { ToDecideConsentsDialogComponent } from './components/to-decide-consents-dialog/to-decide-consents-dialog.component';
import { ConsentsDialogFormItemComponent } from './components/to-decide-consents-dialog/consents-dialog-form-item/consents-dialog-form-item.component';
import { MatBadgeModule } from '@angular/material/badge';
import { HeaderScaniaComponent } from '@core/components/header/header-scania/header-scania.component';
import { HeaderDefaultComponent } from '@core/components/header/header-default/header-default.component';
import { FooterDefaultComponent } from '@core/components/footer/footer-default/footer-default.component';
import { FooterScaniaComponent } from '@core/components/footer/footer-scania/footer-scania.component';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InitOptions } from '@store/app/init-options/models/init-options.model';
import { BusinessFunctionsSwitcherModule } from '@shared/modules/business-functions-switcher/business-functions-switcher.module';
import { loggingInterceptor } from '@core/interceptors/logging.interceptor';
import { ClientErrorHandlerService } from '@core/error-handlers/client-error-handler.service';
import {InitOptionsService} from '@store/app/init-options/init-options.service';
import { MultiFactorAuthenticationDialogComponent } from './components/multi-factor-authentication-dialog/multi-factor-authentication-dialog.component';
import {NgxMaskModule} from 'ngx-mask';

class HttpLoaderFactory {
  constructor(private http: HttpClient, private prefix = 'assets/i18n/', private suffix = '.json') {}

  getTranslation = (currentLang) => {
    return this.http.get<InitOptions>('/api/core/user/Tenants/setup').pipe(
      switchMap((res: InitOptions) => {
        if (res?.locationOptions?.langTranslationUrl) {
          return this.http.get(res.locationOptions.langTranslationUrl);
        } else if (res?.locationOptions?.translationDir && res.locationOptions.translationDir !== 'default') {
          return this.http.get(
            `/api/core/platform/TenantTranslationLanguages/${res.locationOptions.translationDir}/${currentLang}/enduser-file`
          );
        } else {
          return this.http.get(`/api/core/platform/TranslationLanguages/${currentLang}/enduser-file`);
        }
      }),
      catchError((errors: HttpErrorResponse) =>
        this.http.get(`/api/core/platform/TranslationLanguages/${currentLang}/enduser-file`)
      ),
      map((jsonResponse: { ROOT: { [key: string]: string } }) => jsonResponse.ROOT)
    );
  }
}

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderScaniaComponent,
        HeaderDefaultComponent,
        FooterComponent,
        FooterDefaultComponent,
        FooterScaniaComponent,
        LoggedInLayoutComponent,
        FirstLoginAlertDialogComponent,
        FirstLoginDialogComponent,
        NavMenuDirective,
        DisableRouterLinkDirective,
        ToDecideConsentsDialogComponent,
        ConsentsDialogFormItemComponent,
        MultiFactorAuthenticationDialogComponent,
    ],
    imports: [
        CommonModule,
        AppStoreModule,
        RouterModule,
        SharedModule.forRoot(),
        FlexLayoutModule.withConfig({}),
        RouterModule,
        KeycloakAngularModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http) => new HttpLoaderFactory(http),
                deps: [HttpClient],
            },
        }),
        BusinessFunctionsSwitcherModule.forChild(),
        MatBadgeModule,
        NgxMaskModule.forChild(),
    ],
    exports: [TranslateModule, HeaderComponent, FooterComponent, FlexLayoutModule],
    providers: [
        InitOptionsService,
        SnackbarService,
        DialogService,
        HttpErrorService,
        IconsService,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                panelClass: 'app-dialog',
                width: '500px',
                hasBackdrop: true,
                role: 'dialog',
            } as MatDialogConfig,
        },
        KeycloakInitializer,
        {
            provide: APP_INITIALIZER,
            useFactory: (ki: KeycloakInitializer) => ki.init(),
            multi: true,
            deps: [KeycloakInitializer],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        loggingInterceptor,
        {
            provide: ErrorHandler,
            useClass: ClientErrorHandlerService,
        }
    ]
})
export class CoreModule {
  constructor(private iconsService: IconsService) {
    this.iconsService.registerIcons();
  }
}
