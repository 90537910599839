<div mat-dialog-title fxLayout="column">
  <div fxLayoutAlign="space-between center">
    <div class="app-dialog__heading"><b>eBOK</b></div>
    <button mat-icon-button type="button" mat-dialog-close aria-label="Zamknij">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <p><b>Dostęp do systemu możliwy jest po zapoznaniu się z Regulaminem i akceptacją wymaganych zgód.</b></p>
</div>

<div mat-dialog-actions fxLayoutAlign="center" fxLayoutGap="10px">
  <button type="button" class="button button--primary" mat-dialog-close>Wróć do regulaminu</button>
  <button class="button button--secondary" (click)="onLogout()">Opuść stronę</button>
</div>
