<app-loadable [loading]="(settingsLoading$ | async)">
  <app-header></app-header>

  <div class="main-container">
    <main class="content" aria-live="polite">
      <div class="breadcrumb-container">
        <app-breadcrumb></app-breadcrumb>
        <div style="position: relative;">
          <app-session-timer></app-session-timer>
        </div>
      </div>

<!--      <div class="sticky-section" aria-hidden="true" *ngIf="(uiVersion$ | async) === 'default'">-->
<!--        <a routerLink='/services/video-chat' class="chat" aria-hidden="true">-->
<!--          <mat-icon svgIcon="videochat"></mat-icon>-->
<!--        </a>-->
<!--        <a target="_blank" href="https://www.przelewy24.pl/" class="payments" aria-hidden="true">-->
<!--          <img src="../../../../assets/images/przelewy24.svg" title="Logotyp Przelewy24"/>-->
<!--        </a>-->
<!--      </div>-->
      <app-loadable [loading]="(routesLoading$ | async)">
        <router-outlet *ngIf="!((routesLoading$ | async) || (settingsLoading$ | async))"></router-outlet>
      </app-loadable>
    </main>
  </div>

  <app-footer></app-footer>

</app-loadable>
