import { SkipDescriptor } from '@shared/abstract/skip-descriptor.class';
import { TakeDescriptor } from '@shared/abstract/take-descriptor.class';

export interface ListDescriptors {
  pageNumber: number;
  pageSize: number;
  sorts?: SortDescriptor[];
  filters?: FilterDescriptor[];
}

type Connection = '-||-' | '-&&-' | '~';

type Direction = 'asc' | 'desc';

export class SortDescriptor {
  constructor(
    private field: string,
    private direction: Direction,
    private connection: Connection = '~',
  ) {
  }

  serialize = () => `${this.field}-${this.direction}${this.connection}`;
}

export enum Operators {
  EqualTo = 'eq',
  NotEqualTo = 'neq',
  IsNull = 'isnull',
  IsNotNull = 'isnotnull',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  StartsWith = 'startswith',
  NotStartsWith = 'doesnotstartwith',
  EndsWith = 'endswith',
  NotEndsWith = 'doesnotendwith',
  Contains = 'contains',
  NorContains = 'doesnotcontain',
  IsEmpty = 'isempty',
  IsNotEmpty = 'isnotempty',
  Any = 'any',
}

type Operator = string;

export class FilterDescriptor {
  constructor(
    private field: string,
    private operator: Operator,
    private value?: string,
    private connection: Connection = '~',
  ) {
  }

  serialize(): string {
    let filter = `${this.field}-${this.operator}`;
    const value = JSON.stringify(this.value);

    if (value) {
      filter += `-${value}`;
    }

    if (this.connection) {
      filter += `${this.connection}`;
    }

    return filter;
  }
}

export class DataRequest {
  skip?: SkipDescriptor;
  take?: TakeDescriptor;
  sort?: SortDescriptor[];
  filter?: FilterDescriptor[];

  constructor() {
  }
}

