import { createSelector } from '@ngrx/store';
import { appFeatureSelector, AppState } from '../app.reducer';
import { InitOptionsState } from './init-options.reducer';
import { LocationOptions } from '@store/app/init-options/models/location-options';

const adapterInitOptions = createSelector(
  appFeatureSelector,
  (state: AppState) => state,
);

export const selectInitOptions = createSelector(
  adapterInitOptions,
  (state: AppState) => state.initOptions,
);

const loading = createSelector(
  adapterInitOptions,
  (state: AppState) =>
    state.initOptions.loading ||
    state.lang.loading,
);

const loadingInitOptions = createSelector(
  adapterInitOptions,
  (state: AppState) =>
    state.initOptions.loading,
);

const locationOptions = createSelector(
  selectInitOptions,
  (state: InitOptionsState) => state.locationOptions,
);

const uiVersion = createSelector(
  locationOptions,
  (state: LocationOptions) => state && state.uiVersion,
);

const dateFormat = createSelector(
  locationOptions,
  (state: LocationOptions) => state && state.dateFormat,
);

const consentSource = createSelector(
  locationOptions,
  (state: LocationOptions) => state && state.consentSource,
);


const thousandSeparator = createSelector(
  locationOptions,
  (state: LocationOptions) => state && state.thousandSeparator,
);


const decimalSeparator = createSelector(
  locationOptions,
  (state: LocationOptions) => state && state.decimalSeparator,
);

const dashboard = createSelector(
  selectInitOptions,
  (state: InitOptionsState) => null,
);

const time = createSelector(
  selectInitOptions,
  (state: InitOptionsState) => null,
);

const navigation = createSelector(
  selectInitOptions,
  (state: InitOptionsState) => null,
);

const navigationItems = createSelector(
  selectInitOptions,
  (state: InitOptionsState) => state.applicationMenus,
);

const styles = createSelector(
  selectInitOptions,
  (state: InitOptionsState) => state.styles,
);

export const initOptionsSelectors = {
  loading,
  loadingInitOptions,
  locationOptions,
  uiVersion,
  dateFormat,
  dashboard,
  time,
  navigation,
  navigationItems,
  styles,
  consentSource,
  thousandSeparator,
  decimalSeparator,
};
