import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appSortStatus]',
})
export class SortStatusDirective {

  @Input() appSortStatus: number;

  private readonly statusClasses = {
    0: 'no-sort',
  };

  constructor(private el: ElementRef) {
  }

  @HostListener('change') ngOnChanges() {
    this.el.nativeElement.classList.add(this.statusClasses[this.appSortStatus]);
    if (this.appSortStatus !== 0) {
      this.el.nativeElement.classList.remove(this.statusClasses[0]);
    }
  }
}
