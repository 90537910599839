import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { App } from '@environment/models/environment.model';

@Injectable()
export class BusinessFunctionsSwitcherService {
  private reloadSubject = new Subject();

  constructor(private httpClient: HttpClient) {
  }

  getReloadAppListObservable(appListUrl: string): Observable<App[]> {
    return this.reloadSubject.asObservable().pipe(switchMap(() => this.getBusinessFunctionsSwitcherAppListResponse(appListUrl)));
  }

  getBusinessFunctionsSwitcherAppListResponse(appListUrl: string): Observable<App[]> {
    return this.httpClient.get<App[]>(appListUrl);
  }
}
