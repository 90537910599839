import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-absolute-loader',
  templateUrl: './absolute-loader.component.html',
  styleUrls: [ './absolute-loader.component.scss' ],
})
export class AbsoluteLoaderComponent {

  @Input() loading = false;

}
