import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@shared/services/base/base.service';
import { Observable } from 'rxjs';
import { Regulation } from '@store/regulations/models/regulation';
import { RegulationAcceptPayload } from '@store/regulations/models/regulation-accept-payload';
import { SuccessPayload } from '@store/_common/models/common.types';

@Injectable()
export class RegulationsService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  accept(payload: RegulationAcceptPayload): Observable<SuccessPayload<unknown>> {
    const url = `/api/core/user/Regulations/accept`;
    return this.http.put<SuccessPayload<unknown>>(url, payload);
  }

  details(): Observable<{ result: Regulation[] }> {
    //TODO lang code
    const url = `/api/core/user/Regulations/for-accept/pl`;
    return this.http.get<{ result: Regulation[] }>(url);
  }
}
