import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RegulationsService } from '@store/regulations/regulations.service';
import { regulationsAcceptActions } from '@store/regulations/actions/accept.actions';
import { regulationsDetailsActions } from '@store/regulations/actions/details.actions';

@Injectable()
export class RegulationsEffects {
  constructor(
    private actions$: Actions,
    private service: RegulationsService,
  ) {
  }


  
  accept$ = createEffect(() => this.actions$.pipe(
    ofType(regulationsAcceptActions.loading),
    switchMap(({ data }) =>
      this.service.accept(data).pipe(
        map(() => regulationsAcceptActions.loaded()),
        catchError((errors) => of(regulationsAcceptActions.failed({ errors }))),
      ),
    ),
  ));

  
  details$ = createEffect(() => this.actions$.pipe(
    ofType(regulationsDetailsActions.loading),
    switchMap(() =>
      this.service.details().pipe(
        map(({ result }) => {
          return regulationsDetailsActions.loaded({ response: result });
        }),
        catchError((errors) => of(regulationsAcceptActions.failed({ errors }))),
      ),
    ),
  ));

}
