import { createAction, props } from '@ngrx/store';
import { FailPayload, SendPayload } from '@store/_common/models/common.types';
import { RegulationAcceptPayload } from '@store/regulations/models/regulation-accept-payload';

const loading = createAction('[Regulations accept] Loading', props<SendPayload<RegulationAcceptPayload>>());
const loaded = createAction('[Regulations accept] Success');
const failed = createAction('[Regulations accept] Failed', props<FailPayload>());
const clear = createAction('[Regulations accept] Clear state');

export const regulationsAcceptActions = {
  loading,
  loaded,
  failed,
  clear,
};
