import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { get, merge } from 'lodash-es';
import { BaseListState } from '../models/base-list-state';

function initialStateCreator(paramsKey): BaseListState {
  return {
    params: sessionStorage.getItem(paramsKey) ? JSON.parse(sessionStorage.getItem(paramsKey)) : {},
  };
}

export function listReducer(types, paramsKey) {
  return createReducer(
    initialStateCreator(paramsKey),
    on(types.loading, (state) =>
      produce(state, (draftState) => {
        draftState.loading = true;
      }),
    ),
    on(types.loaded, (state, { records, totalRecords }) =>
      produce(state, (draftState) => {
        draftState.records = records;
        draftState.totalRecords = totalRecords;
        draftState.loading = false;
        draftState.success = true;
      }),
    ),
    on(types.updateParams, (state, { params }) =>
      produce(state, (draftState) => {
        draftState.params = merge(draftState.params, params);
      }),
    ),
    on(types.clearParams, (state) =>
      produce(state, (draftState) => {
        draftState.params = {};
      }),
    ),
    on(types.failed, (state, payload) =>
      produce(state, (draftState) => {
        draftState.loading = false;
        draftState.success = false;
        draftState.errors = get(payload, 'error');
      }),
    ),
    on(types.failedFiltersLoad, (state, payload) =>
      produce(state, (draftState) => {
        draftState.loading = false;
        draftState.success = false;
        draftState.errors = get(payload, 'error');
      }),
    ),
    on(types.loadedFilters, (state, filterTypes) =>
      produce(state, (draftState) => {
        draftState.filterTypes = filterTypes;
        draftState.loading = false;
      }),
    ),
  );
}
