export const Permission = {
  USER_LOGIN: 'u-base-login',
  USER_ACCEPTED: 'reg-accepted',

  PERM_BASE: 'u-base',
  PERM_BASE_DASHBOARD: 'u-base-dash',
  PERM_BASE_LOGIN: 'u-base-login',

  PERM_CONTACT: 'u-contact',
  PERM_CONTACT_FORM: 'u-contact-send',

  PERM_DOCUMENTS: 'u-doc',
  PERM_DOCUMENTS_INVOICES: 'u-doc-inv',
  PERM_DOCUMENTS_INVOICES_MULTI_DOWNLOAD: 'u-doc-inv-multi-download',
  PERM_DOCUMENTS_INVOICES_SINGLE_DOWNLOAD: 'u-doc-inv-single-download',

  PERM_REPORT: 'u-rep',
  PERM_REPORT_TURNOVER: 'u-rep-turnover',

  PERM_REPOSITORY: 'u-repository',

  PERM_DOCUMENTS_CONTRACTS: 'u-doc-cntrt',
  PERM_DOCUMENTS_CONTRACTS_MULTI_DOWNLOAD: 'u-doc-cntrt-multi-download',
  PERM_DOCUMENTS_CONTRACTS_SINGLE_DOWNLOAD: 'u-doc-cntrt-single-download',

  PERM_KNOWLEDGEBASE: 'u-knowledgebase',

  PERM_MESSAGES: 'u-messages',

  PERM_PAYMENTS: 'u-payments',

  PERM_PROFILE: 'u-pro',
  PERM_PROFILE_DATA: 'u-pro-data',
  PERM_PROFILE_DATA_EDIT: 'u-pro-data-edit',
  PERM_PROFILE_CONSENTS: 'u-pro-consents',
  PERM_PROFILE_CONSENTS_EDIT: 'u-pro-consents-edit',

  PERM_PROFILE_CONSENTS_VIEW: 'u-pro-consents-view',
  PERM_PROFILE_REG_VIEW: 'u-pro-consents-reg-view',

  PERM_SERVICE: 'u-service',
  PERM_SVC: 'u-svc',

  PERM_MOCKS: 'u-mocks',
};
