import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@environment/environment';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Component({
  selector: 'app-unauthorized-dialog',
  templateUrl: './unauthorized-dialog.component.html',
  styleUrls: [ './unauthorized-dialog.component.scss' ],
})
export class UnauthorizedDialogComponent {

  constructor(private keycloakService: KeycloakService, private tenantConfigService: TenantConfigService) {
  }

  logout(): void {
    this.keycloakService.logout(this.tenantConfigService.appBaseUrl);
  }
}
