<ng-container [formGroup]="formRef">

  <ul class="consent-list">
    <ng-container formArrayName="consents">

      <li class="consent-list__item" *ngFor="let control of controls; index as i" [formGroupName]="i">

        <p class="consent-list__paragraph">{{ control?.value?.header || ('APP.GENERAL.NO_RESULT' | translate) }}</p>
        <mat-radio-group class="consent-list__radio" formControlName="decision">
          <mat-radio-button
            [value]="customerDecisionEnum.ACCEPTED">{{'APP.GENERAL.YES' | translate}}</mat-radio-button>
          <mat-radio-button
            [value]="customerDecisionEnum.DENIED">{{'APP.GENERAL.NO' | translate}}</mat-radio-button>
        </mat-radio-group>

      </li>

    </ng-container>
  </ul>

</ng-container>
