<ng-container *ngIf="loading">
  <div class="absolute-loader">
    <div class="absolute-loader__container">
      <div class="bouncing-loader">
        <svg fill='none' height='150' stroke-linecap='round' stroke-width='5' viewBox='0 0 200 200'
             width='150' xmlns='http://www.w3.org/2000/svg'>
          <defs>
            <linearGradient gradientUnits="userSpaceOnUse" id="gradient">
              <stop stop-color="#00BC9B"/>
              <stop stop-color="#1F61DC"/>
              <animateTransform attributeName="gradientTransform" calcMode="spline" dur="2s"
                                keySplines="0.64 0.25 0.36 0.75"
                                repeatCount="indefinite" type="rotate" values="120; 480;"/>
            </linearGradient>
          </defs>
          <polygon
            class="st0"
            id="polygon12" points="14,150.4 14,49.5 33.5,61.3 33.3,139.4 100,177.3 138.5,155.7 148.3,172.1 100.5,200 "
            style="fill:none;" transform="matrix(0.98,0,0,0.98,0.28,2)"/>
          <polygon
            class="st0"
            id="polygon14" points="68.9,40.8 58.5,24 100.8,0 187,50.2 187,149.9 167.7,138.1 167.7,59.3 101,21.5 "
            style="fill:none;" transform="matrix(0.98,0,0,0.98,0.28,2)"/>
        </svg>
      </div>
    </div>
  </div>
  <div class="absolute-loader-bg"></div>
</ng-container>
