import { Component, OnInit } from '@angular/core';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {

  uiVersion$ = this.store.select(initOptionsSelectors.uiVersion);

  constructor(private store: Store) {}
}
