import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface InputTextError {
  text: string;
  name: string;
}

export type InputTextErrors = InputTextError[];

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: [ './input-text.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTextComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() errors: InputTextErrors = [];
  @Input() disabled = false;

  constructor() {
  }

  ngOnInit(): void {
    if (!(this.control instanceof UntypedFormControl)) {
      console.error(new Error('No control defined to <app-input-text>'));
    }

    if (!(typeof this.label === 'string')) {
      console.error(new Error('No label defined to <app-input-text>'));
    }
  }

}
