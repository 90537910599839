import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number, omitModulo = false): string {
    const minutes = omitModulo ? Math.floor(value / 60) : Math.floor(value / 60) % 60;
    const seconds = value % 60;
    return `${this.padding(minutes)}${minutes} min ${this.padding(seconds)}${seconds} s`;
  }

  private padding(time): string {
    return time < 10 ? '0' : '';
  }
}
