import { Injectable } from '@angular/core';
import { DataRequest, FilterDescriptor, Operators, SortDescriptor } from '@shared/abstract/list-descriptor.abstract';
import { DescriptorSerializer } from '@shared/abstract/descriptor-serializer.class';
import omitBy from 'lodash-es/omitBy';
import isNil from 'lodash-es/isNil';
import get from 'lodash-es/get';
import { TakeDescriptor } from '@shared/abstract/take-descriptor.class';
import { SkipDescriptor } from '@shared/abstract/skip-descriptor.class';
import omit from 'lodash-es/omit';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  protected stringify(request: DataRequest): string {
    const descriptorSerializer = new DescriptorSerializer();
    let params = '?';

    for (const property in request) {
      if (request.hasOwnProperty(property)) {
        const descriptor = request[property];

        params += `${property}=${descriptorSerializer.serialize(descriptor)}&`;
      }
    }
    return params.slice(0, params.length - 1);
  }

  protected prepareParams(params, pagination = true): string {
    const filteredParams = omitBy(params, isNil);
    const pageNumber = isNil(params.currentPage) || params.currentPage === 0 ? 0 : params.currentPage - 1;
    const pageSize = get(params, 'pageSize', 10);
    const request = new DataRequest();

    if (pagination) {
      request.take = new TakeDescriptor(pageSize);
      request.skip = new SkipDescriptor(pageSize * pageNumber);
    }

    const filterArray = [];

    const filteredObj: [string, any][] = Object.entries<any>(
      omit(filteredParams, ['sort', 'currentPage', 'pageSize'])
    ).filter(([key, value]) => !!value.value);

    for (const [key, value] of filteredObj) {
      let filterValue = value.value;

      if (value.operator === Operators.GreaterThanOrEqual) {
        if (!moment(filterValue).isValid()) {
          continue;
        }
        filterValue = moment(filterValue).startOf('day');
      }

      if (value.operator === Operators.LessThanOrEqual) {
        if (!moment(filterValue).isValid()) {
          continue;
        }
        filterValue = moment(filterValue).endOf('day');
      }

      if (value.operator === Operators.IsNull) {
        if (!['ISNULL', 'ISNOTNULL'].includes(filterValue)) {
          continue;
        }
        filterArray.push(new FilterDescriptor(key.split('__')[0], filterValue.toLowerCase()));
        continue;
      }

      filterArray.push(new FilterDescriptor(key.split('__')[0], value.operator, filterValue));
    }
    if (filterArray.length > 0) {
      request.filter = new Array<FilterDescriptor>(...filterArray);
    }

    const sortArray = [];
    if (filteredParams.hasOwnProperty('sort') && !!params.sort) {
      const val = params.sort.split('-');
      sortArray.push(new SortDescriptor(val[0], val[1] === 'true' ? 'asc' : 'desc'));
      request.sort = new Array<SortDescriptor>(...sortArray);
    }

    return this.stringify(request);
  }
}
