import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CONSENTS_REDUCER_TOKEN, consentsProvider, consentsReducerKey } from '@store/consents/consents.reducer';
import { ConsentsEffects } from '@store/consents/consents.efects';
import { ConsentsService } from '@store/consents/consents.service';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(consentsReducerKey, CONSENTS_REDUCER_TOKEN),
    EffectsModule.forFeature([
      ConsentsEffects,
    ]),
  ],
  providers: [
    consentsProvider,
    ConsentsService,
  ],
})
export class ConsentsStoreModule {
}
