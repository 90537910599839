import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { BaseComponent } from '@shared/abstract/base-component.abstract';
import { selectLoading } from '@store/app/loading/loading.selectors';
import { Store } from '@ngrx/store';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';

export interface Breadcrumb {
  url: string;
  label: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [ './breadcrumb.component.scss' ],
})
export class BreadcrumbComponent extends BaseComponent {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  breadcrumbs$;

  breadcrumbs: Breadcrumb[];

  routesLoading$ = this.store$.select(selectLoading);
  title$ = this.breadcrumbService.getTitle();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private store$: Store,
  ) {
    super();
    store$.select(selectLoading).pipe(
      filter((loading: boolean) => !loading),
      takeUntil(this.destroyed$),
    ).subscribe(() => this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root));
  }

  ngOnInit(): void {
    this.breadcrumbs$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      takeUntil(this.destroyed$),
    ).subscribe(() => {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      if (!isNil(this.title$.getValue())) {
        this.breadcrumbs.push({ label: this.title$.getValue(), url: '' });
      }
    });

    this.title$.pipe(
      distinctUntilChanged(),
      filter((title: string) => !isNil(title)),
      takeUntil(this.destroyed$),
    ).subscribe((res) => {
      this.breadcrumbs.push({ label: res, url: '' });
    });
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {

    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNil(label)) {
        breadcrumbs.push({ label, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
