import { Component, ElementRef, ViewChild } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirstLoginAlertDialogComponent } from '@core/components/first-login-alert-dialog/first-login-alert-dialog.component';
import { Store } from '@ngrx/store';
import { regulationsAcceptActions } from '@store/regulations/actions/accept.actions';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { regulationsAcceptSelectors } from '@store/regulations/selectors/accept.selectors';
import { Router } from '@angular/router';
import { regulationsDetailsActions } from '@store/regulations/actions/details.actions';
import { regulationsDetailsSelectors } from '@store/regulations/selectors/details.selectors';
import { Regulation } from '@store/regulations/models/regulation';
import { RegulatoryConsent } from '@store/regulations/models/regulatory-consent';
import { RegulationAcceptPayload } from '@store/regulations/models/regulation-accept-payload';
import { BaseComponent } from '@shared/abstract/base-component.abstract';

@Component({
  selector: 'app-first-login-dialog',
  templateUrl: './first-login-dialog.component.html',
  styleUrls: [ './first-login-dialog.component.scss' ],
})
export class FirstLoginDialogComponent extends BaseComponent {

  acceptLoading$ = this.store$.select(regulationsAcceptSelectors.loading);
  acceptSuccess$ = this.store$.select(regulationsAcceptSelectors.success);
  detailsLoading$ = this.store$.select(regulationsDetailsSelectors.loading);
  details$ = this.store$.select(regulationsDetailsSelectors.data);

  form: UntypedFormGroup;
  isBottomReached = true;
  profileData;

  @ViewChild('regulation', { static: false }) regulation: ElementRef;

  isRef = false;


  get controls() {
    return (this.form.get('consents') as UntypedFormArray).controls;
  }

  constructor(private keycloakService: KeycloakService, private fb: UntypedFormBuilder,
              private dialogRef: MatDialogRef<FirstLoginDialogComponent>,
              private dialog: MatDialog,
              private store$: Store,
              private router: Router) {
    super();
    this.profileData = this.keycloakService.getKeycloakInstance().tokenParsed;
  }


  ngOnInit(): void {
    this.store$.dispatch(regulationsDetailsActions.loading());

    this.details$.pipe(
      distinctUntilChanged(),
      filter((res) => !!res),
      takeUntil(this.destroyed$),
    ).subscribe((res: Regulation) => {
      this.createForm(res);
    });

    this.acceptSuccess$.pipe(
      distinctUntilChanged(),
      filter((success: boolean) => !!success),
      takeUntil(this.destroyed$),
    ).subscribe(() => {
      this.router.navigate([ '/profile' ]);
      this.dialogRef.close();
    });


  }

  onSubmit(): void {
    if (this.isBottomReached) {

      this.markFormGroupTouched(this.form);

      if (this.form.valid) {
        this.store$.dispatch(regulationsAcceptActions.loading({ data: this.dataFactory() }));
      }

    }
  }

  onCloseDialog(): void {
    this.dialog.open(FirstLoginAlertDialogComponent, {
      disableClose: true,
      panelClass: [ 'app-dialog', 'backgroundDark' ],
    });
  }

  onBottomReached(): void {
    this.isBottomReached = true;
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).enable();
    });
  }

  ngOnDestroy(): void {
    this.store$.dispatch(regulationsDetailsActions.clear());
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private dataFactory(): RegulationAcceptPayload {
    const formVal = this.form.value;

    return {
      regulations: [ {
        id: formVal.id,
        consents: formVal.consents.map(({ id, accepted }) => ({ id, accepted })),
      } ],
    };
  }

  private createForm(data: Regulation): void {
    const items = data[0].regulatoryConsents;
    this.form = this.fb.group({
      id: data[0].id,
      consents: this.fb.array([]),
    });

    items.forEach((elem: RegulatoryConsent) => {
      (this.form.get('consents') as UntypedFormArray).push(this.fb.group({
        id: elem.id,
        accepted: this.fb.control({ value: false, disabled: false }, Validators.requiredTrue),
        value: elem.consentContentTranslationValue,
      }));
    });
  }

  private markFormGroupTouched(formGroup: UntypedFormGroup): void {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  onInitScroll(): void {
    if (this.regulation) {
      this.isRef = true;
      const boxHeight = this.regulation?.nativeElement?.scrollHeight;
      const regHeight = this.regulation?.nativeElement?.firstChild?.scrollHeight;
      if (regHeight < boxHeight) {
        this.onBottomReached();
      }
    }
  }

}
