import {  KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TenantConfigService } from '@core/services/tenant-config.service';

export interface TenantRealm {
  appBaseUrl: string;
  idpUrl: string;
  idpClientId: string;
  mocks: boolean;
  production: boolean;
  realmName: string;
  unauthorizedUserRedirectUrl?: string;
  logFrontErrors?: boolean;
}

@Injectable()
export class KeycloakInitializer {

  constructor(
    private keycloak: KeycloakService,
    private http: HttpClient,
    private tenantConfigService: TenantConfigService,
  ) {
  }

  initKeycloak = (config: Keycloak.KeycloakConfig) =>
    this.keycloak
      .init({
        config,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
          pkceMethod: 'S256'
        },
      })
      .catch(() => {
        const keycloakInstance = this.keycloak.getKeycloakInstance();
        const { authenticated } = keycloakInstance;

        if (!authenticated) {
          localStorage.clear();
          location.reload();
        }
      });

  init() {
    return () => new Promise<void>((res) => {

      return this.http.get('/api/core/user/Tenants/config').toPromise().then((data: TenantRealm) => {
        this.tenantConfigService.setConfiguration(data);
        return this.initKeycloak({
          url: `${data.idpUrl}/auth`,
          realm: data.realmName,
          clientId: data.idpClientId,
        }).then(() => res());
      });

    });
  }

}
