import { Component, Input } from '@angular/core';
import { Breadcrumb } from '@shared/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: [ './breadcrumb-item.component.scss' ],
})
export class BreadcrumbItemComponent {

  @Input() item: Breadcrumb;
  @Input() isLast = false;

}
