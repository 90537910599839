import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringifiedArrayIncludes'
})
export class StringifiedArrayIncludesPipe implements PipeTransform {

  transform(array: string, value: string, separator: string = ','): boolean {
    return array?.split(separator)?.includes(value);
  }

}
