import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTableComponent } from './components/app-table/app-table.component';


@NgModule({
  declarations: [
    AppTableComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AppTableComponent,
  ],
})
export class AppTableModule {
}
