import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appProvider, appReducerKey, APP_REDUCER_TOKEN } from './app.reducer';
import { InitOptionsEffects } from './init-options/init-options.efects';
import { InitOptionsService } from './init-options/init-options.service';
import { LangEffects } from './lang/lang.efects';
import { AppService } from '@store/app/app.service';
import { AppEffects } from '@store/app/app.efects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(appReducerKey, APP_REDUCER_TOKEN),
    EffectsModule.forFeature([
      InitOptionsEffects,
      LangEffects,
      AppEffects
    ])
  ],
  providers: [
    appProvider,
    InitOptionsService,
    AppService
  ]
})
export class AppStoreModule {
}
