import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'startOfDay',
})
export class StartOfDay implements PipeTransform {
    constructor(){}

    transform(value: string){
        if(!value){
                return null;
            }
            else{
                return moment.utc(value).startOf('day');
            }
    }
}