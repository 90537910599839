import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appDpdDateMask]',
})
export class DpdDateMaskDirective implements OnDestroy {

  mask = [ /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/ ]; // yyyy-mm-dd
  maskedInputController;

  constructor(private element: ElementRef) {
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask,
      guide: false,
    });
  }

  ngOnDestroy(): void {
    this.maskedInputController.destroy();
  }
}
