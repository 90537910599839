import { Injectable } from '@angular/core';
import { TenantRealm } from '@core/utils';

@Injectable({
  providedIn: 'root',
})
export class TenantConfigService {
  private configuration: TenantRealm = {
    realmName: '',
    idpUrl: '',
    idpClientId: '',
    appBaseUrl: '',
    unauthorizedUserRedirectUrl: '',
    mocks: false,
    production: true,
    logFrontErrors: false,
  };

  get appBaseUrl(): string {
    return this.configuration?.appBaseUrl;
  }

  get unauthorizedUserRedirectUrl(): string {
    return this.configuration?.unauthorizedUserRedirectUrl;
  }

  get mocks(): boolean {
    return this.configuration?.mocks;
  }

  get logFrontErrors(): boolean {
    return this.configuration?.logFrontErrors;
  }

  constructor() {}

  setConfiguration(config: TenantRealm): void {
    this.configuration = config;
  }
}
