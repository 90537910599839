import { Injectable } from '@angular/core';
import { SnackbarService } from '../snackbar/snackbar.service';
import { DialogService } from '../dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { exhaustMap, delay } from 'rxjs/operators';

enum errorCodes {
  // CLIENT ERRORS
  'BAD_REQUEST' = 400,
  'UNAUTHORIZED' = 401,
  'FORBIDDEN' = 403,
  'NOT_FOUND' = 404,
  'METHOD_NOT_ALLOWED' = 405,
  'REQUEST_TIMEOUT' = 408,

  // SERVER ERROR
  'INTERNAL_SERVER_ERROR' = 500,
  'NOT_IMPLEMENTED' = 501,
  'BAD_GATEWAY' = 502,
  'SERVICE_UNAVALIABLE' = 503,
  'GATEWAY_TIMEOUT' = 504,
}


@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor(
    private snackBarService: SnackbarService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) { }


  showError(error: HttpErrorResponse): Observable<never> {
    const errorMethods = {
      [errorCodes.BAD_REQUEST]: this.showSnackBar(errorCodes[error.status]),
    };

    const action = errorMethods[error.status];

    return action();
  }

  private showSnackBar = (translate) => () => {
    return this.translateService.get(translate)
      .pipe(
        exhaustMap((text) => this.snackBarService.showErrorSnackbar(text).afterDismissed()),
        delay(100),
      );
  }
}
