import { Injectable } from '@angular/core';
import { MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  // tslint:disable-next-line: no-any
  showErrorSnackbar = (message): MatSnackBarRef<any> =>
    this.snackBar.open(message, null, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    })

}
