import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-loadable',
  templateUrl: './loadable.component.html',
  styleUrls: ['./loadable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadableComponent implements OnInit {

  @Input() loading: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
