<div mat-dialog-title fxLayoutAlign="space-between center">
  <h2 class="app-dialog__heading"></h2>
  <button mat-icon-button mat-dialog-close type="button" aria-label="Zamknij">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="confirm-dialog">
    <div class="confirm-dialog__icon">
      <ng-container *ngIf="confirmation; else confirmedIcon">
        <mat-icon>help</mat-icon>
      </ng-container>

      <ng-template #confirmedIcon>
        <mat-icon svgIcon="save"></mat-icon>
      </ng-template>

    </div>
    <p class="confirm-dialog__paragraph">{{ title }}</p>
  </div>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
  <ng-container *ngIf="confirmation; else confirmed">
    <button class="button button--small button--secondary" mat-dialog-close>{{ 'APP.GENERAL.CANCEL' | translate }}</button>
    <button class="button button--small button--primary" (click)="onClose()">{{ 'APP.GENERAL.LEAVE' | translate }}</button>
  </ng-container>

  <ng-template #confirmed>
    <button class="button button--small button--primary" mat-dialog-close>Ok</button>
  </ng-template>
</div>

