import { Directive, ElementRef, OnInit } from '@angular/core';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Directive({
  selector: '[appHideMocks]',
})
export class HideMocksDirective implements OnInit {

  constructor(private el: ElementRef, private tenantConfigService: TenantConfigService) {

  }

  ngOnInit() {
    if (!this.tenantConfigService.mocks) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
