<ng-container *ngIf="form">
  <form [formGroup]="form" (submit)="onSubmit()" #ngForm="ngForm">
    <div mat-dialog-title fxLayout="column">
      <div fxLayoutAlign="space-between center">
        <div class="app-dialog__heading">eBOK</div>
        <button mat-icon-button (click)="onCloseDialog()" type="button" aria-label="Zamknij">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="welcome">
        <div class="welcome-header">
          <b>Witaj</b> {{ (profileData?.given_name || '') + ' ' + (profileData?.family_name || '') }}
        </div>
        <p>
          Aby w pełni korzystać z funkcjonalności systemu eBOK, konieczne jest odznaczenie zgód. Zapoznaj się z
          Regulaminem i Polityką prywatności.
        </p>
      </div>
    </div>

    <div class="first-login-content" mat-dialog-content>
      <div class="spinner">
        <mat-progress-bar *ngIf="acceptLoading$ | async" mode="indeterminate"></mat-progress-bar>
      </div>

      <ng-container *ngFor="let item of details$ | async">
        <ng-scrollbar
          class="regulations-scrollbar"
          (updated)="!isRef && onInitScroll()"
          (reachedBottom)="onBottomReached()">
          <div #regulation class="regulations" style="height: 330px">
            <div class="regulation__content">
              <h3>{{ item?.headerTranslationValue }}</h3>
              <div [innerHTML]="item?.contentTranslationValue"></div>
            </div>
          </div>
        </ng-scrollbar>

        <div class="error-container" fxLayoutAlign="start center">
          <ng-container *ngIf="!isBottomReached && ngForm.submitted"
            >Zapoznaj się z Regulaminem, a następnie zaznacz wymagane zgody.
            <mat-icon>info</mat-icon>
          </ng-container>
        </div>

        <div class="confirmations">
          <ng-container formArrayName="consents">
            <div
              *ngFor="let control of controls; index as i"
              [formGroupName]="i"
              fxLayout="row"
              fxLayoutGap="1rem"
              fxLayoutAlign="start"
              fx>
              <input type="checkbox" formControlName="accepted" />
              <p class="my-3"><span class="required" aria-hidden="true">* </span>{{ control.value.value }}</p>
            </div>
          </ng-container>
        </div>
        <div class="error-container" fxLayoutAlign="start center">
          <ng-container *ngIf="isBottomReached && form.touched && form.invalid"
            >Zgody są wymagane.
            <mat-icon>info</mat-icon>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div mat-dialog-actions fxLayoutAlign="flex-end" fxLayoutGap="10px">
      <button type="button" class="button button--secondary" (click)="onCloseDialog()">{{ 'APP.GENERAL.CANCEL' | translate }}</button>
      <button class="button button--primary--state-success" [disabled]="acceptLoading$ | async">{{ 'APP.GENERAL.APPLY' | translate }}</button>
    </div>
  </form>
</ng-container>
