import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type VerifyMfaCodeResponse = 'CORRECT' | 'INCORRECT' | 'EXPIRED' | 'INTERNAL_ERROR' | 'DUPLICATED_REQUEST';

export type GenerateMfaResponse = { codeExpirationDate: string };

@Injectable({
  providedIn: 'root',
})
export class MultiFactorAuthenticationService {
  constructor(private readonly httpClient: HttpClient) {}

  generateMfaCode(): Observable<GenerateMfaResponse> {
    const url = `/api/core/user/Verification/generate-mfa-code`;
    return this.httpClient.post<GenerateMfaResponse>(url, null);
  }

  verifyMfaCode(code: string): Observable<VerifyMfaCodeResponse> {
    const url = `/api/core/user/Verification/verify-mfa-code`;
    return this.httpClient.post(url, { code }, { responseType: 'text' }) as Observable<VerifyMfaCodeResponse>;
  }
}
