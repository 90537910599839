import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { TenantConfigService } from '@core/services/tenant-config.service';
import { MenuItem } from '@core/models/menu-item';
import { NavigationUtil } from '@core/utils/navigation.util';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private data: MenuItem[] = NavigationUtil.getFilteredMenuItems(
    this.tenantConfigService.mocks,
    this.keycloakService.getUserRoles()
  );

  get menu(): MenuItem[] {
    return this.data;
  }

  get baseRoute(): string {
    let link = '';
    const menuItem = this.menu[0];
    if (menuItem?.children?.length) {
      link = menuItem.children[0].path;
    } else if (menuItem?.path) {
      link = menuItem.path + '';
    } else {
      return '/no-permissions';
    }
    return link;
  }

  constructor(private keycloakService: KeycloakService, private tenantConfigService: TenantConfigService) {}
}
