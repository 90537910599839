import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class IconsService {
  icons: string[] = [
    // animated icons
    'animated',
    // role icons
    'role-add',
    'role-copy',
    // global icons
    'jednostka_organizacyjna',
    'menu_user',
    'cross',
    'check',
    'block',
    'logout',
    'unblock',
    'calendar',
    'details',
    'compare',
    'search',
    'server-time-calendar',
    'exclamation-mark-circle-ico',
    'edit',
    'close',
    'save',
    'send',
    'delete',
    'settings',
    'videochat',
    'password',
    'password-reset',
    'faq',
    'faq-secondary',
    'regulation',
    'refresh',
    'print',
    'filters',
    'phone',
    'phone-secondary',
    'mobile',
    'download',
    'pdf-icon',
    'message',
    'message-new',
    'message-response',
    'message-old',
    'create',
    'warning',
    'related-documents',
    'new-message',
    // service desk icons
    'service-desk-message',
    'service-desk-phone',
    'service-desk',
    'service-desk-list',
    'service-desk-view',
    'service-report',
    'back',
    'info',
    // user icons
    'user',
    'user-export',
    'user-import',
    'user-add',
    // user group add
    'user-group-add',
    // group icons
    'group',
    // document icons
    'document',
    'document-add',
    'document-import',
    'document-export',
    'document-details',
    'document-generator',
    'document-multiple',
    'document-multiple-add',
    'document-related',
    'document-distribution',
    'document-disabled-add',
    'document-settlement',
    // folder icons
    'folder',
    'folder-add',
    'folder-multiple',
    // payment icons
    'cart',
    'payment',
    'latest-orders',

    'create-from-template',
    'sign',
    'document-operations',
    'repository',
    'send-to-accept',
    'template-save',
    'vindicate',
    'status-change',
    'assign',
    'add',
    'label-add',
    'share',
    'copy',
    'vindicate-soft',
    'purpose-create',
    'purpose-verify',

    'marketing-data-icon',
    'regulation-icon',
    // menu icons
    'menu-dashboard',
    'l_menu-dashboard',
    'menu-clients',
    'menu-contractors',
    'menu-documents',
    'l_menu-documents',
    'menu-marketing',
    'menu-operations',
    'menu-profile',
    'l_menu-profile',
    'menu-reports',
    'menu-services',
    'menu-settings',
    'menu-contact',
    'l_menu-contact',
    'menu-payments',
    'l_menu-payments',
    'menu-services-2',
    'l_menu-services-2',
    'menu-settlements',
    'l_menu-settlements',
    // submenu icons
    'submenu-services-2',
    // submenu document
    'submenu-doc-agreements',
    'l_submenu-doc-agreements',
    'submenu-doc-annexes',
    'submenu-doc-complaints',
    'submenu-doc-forms',
    'submenu-doc-invoices',
    'submenu-doc-settlement',
    'submenu-doc-survey',
    // submenu operations
    'submenu-debt-doc',
    'submenu-digitalization',
    'submenu-distribution',
    'submenu-edowod',
    'submenu-epuap',
    'submenu-generate',
    'submenu-przelewy',
    'submenu-qualified',
    'submenu-sign',
    'submenu-template',
    // submenu contractors and clients
    'submenu-client-kpi',
    'submenu-client-settings',
    'submenu-debt',
    'submenu-faq',
    'l_submenu-faq',
    'submenu-goals',
    'submenu-history',
    'submenu-messages',
    'l_submenu-messages',
    'submenu-repo',
    // submenu services
    'submenu-courier',
    'submenu-printers',
    'submenu-servicedesk',
    'l_submenu-servicedesk',
    'submenu-videochat',
    // submenu marketing
    'submenu-discount',
    'submenu-mail',
    'submenu-offer',
    'submenu-platform',
    'submenu-sms',
    'submenu-statistic',
    'submenu-survey',
    // submenu reports
    'submenu-cyclic',
    'submenu-dynamic',
    'submenu-managment',
    'submenu-payments',
    'submenu-processing',
    'submenu-sales',
    // submenu profile
    'submenu-profile',
    'submenu-profile-approval',
    // submenu settings
    'submenu-settings-approval',
    'submenu-settings-contractor',
    'submenu-settings-doc',
    'submenu-settings-report',
    'submenu-settings-user',
    'knowledge-base',
    // dashboard icons
    'dashboard-settings',
    'settlements-icon',
    // paginator icons
    'paginator-left',
    'paginator-right',
    'consent-management',
    'keyboard_arrow_down',
    // social icons
    'social_facebook',
    'social_lin',
    'social_yt',
    'social_twitter',
    'social_ig',
    // scania icons
    'scania-admin-role',
    'scania-contact',
    'scania-contracts',
    'scania-customer-settings',
    'scania-customers',
    'scania-dashboard',
    'scania-documents',
    'scania-input-data',
    'scania-invoices',
    'scania-marketing',
    'scania-messages',
    'scania-org-units',
    'scania-profile',
    'scania-relationship',
    'scania-reports',
    'scania-repository',
    'scania-services',
    'scania-settings',
    'scania-user-role',
    'scania-user-settings',
    'scania-users',
    'details',
    'preview',
  ];

  customUiIcons: string[] = ['ecminfinite/details'];

  private iconBaseUrl = 'assets/images/icons';
  private defaultUIVersion = 'default';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId,
    private store: Store
  ) {}

  registerIcons(): void {
    this.loadIcons();
    this.store
      .select(initOptionsSelectors.uiVersion)
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        tap((uiVersion: string) => {
          this.loadIcons(uiVersion);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private loadIcons(uiVersion?: string): void {
    this.icons.forEach((key) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.iconBaseUrl}/${key}.svg`)
      );
    });
    if (uiVersion && uiVersion !== this.defaultUIVersion) {
      this.customUiIcons.forEach((key) => {
        const [iconUiVersion, iconKey] = key.split('/');
        if (iconUiVersion === uiVersion) {
          this.matIconRegistry.addSvgIcon(
            iconKey,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.iconBaseUrl}/${key}.svg`)
          );
        }
      });
    }
  }
}
