<div class="timer">
  <div class="data-timer">
    <div class="data-timer__label">{{ 'APP.SESSION' | translate }}:</div>
    <div class="data-timer__item" fxLayoutAlign="center center">
      <span class="page-header__timer" role="timer">{{ timer$ | async | timeFormat }}</span>
    </div>
  </div>
  <div class="server-time">
    <mat-icon [svgIcon]="'server-time-calendar'"></mat-icon>
    <span role="timer">{{ serverTime | appDatePipe: 'dd-MM-yyyy, HH:mm:ss' }}</span>
  </div>
</div>




