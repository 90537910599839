import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { get } from 'lodash-es';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: [ './table-header.component.scss' ],
})
export class TableHeaderComponent {

  paginatorCurrentPage;

  @Input() allRecords = false;
  @Input() isAllSelected = false;

  @Input() checkVisible = true;
  @Input() checkAllVisible = true;
  @Input() paginatorLength = 0;

  @Input() set currentPage(value: number) {
    this.paginatorCurrentPage = !!value ? value : 1;
  }

  @Input() selectedRecords: { [key: string]: Set<number> } = {};

  @Output() handleCheckAllRecords = new EventEmitter();
  @Output() handleToggleAllCheck = new EventEmitter<MatCheckboxChange>();


  get recordsSizeByPage(): number {
    return get(this.selectedRecords[this.paginatorCurrentPage], 'size', 0);
  }

  get size(): number {
    return this.allRecords ? this.paginatorLength : Object.values(this.selectedRecords).reduce((prev, curr) => prev + curr.size, 0);
  }

}
