import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, filter, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { InitOptionsService } from './init-options.service';
import { of } from 'rxjs';
import { HttpErrorService } from 'src/app/core/services/http-error/http-error.service';
import { InitOptions } from './models/init-options.model';
import { initOptionsActions } from './init-options.actions';
import { TranslateService } from '@ngx-translate/core';
import { langActions } from '../lang/lang.actions';
import { MatDialog } from '@angular/material/dialog';
import { MultiFactorAuthenticationDialogComponent } from '@core/components/multi-factor-authentication-dialog/multi-factor-authentication-dialog.component';
import { KeycloakService } from 'keycloak-angular';
import { Permission } from '@core/enums/permission.enum';
import { toDecideConsentListActions } from '@store/consents/actions/to-decide-consent-list.actions';
import { appUnreadMessagesActions } from '@store/app/unread-messages/unread-messages.actions';
import { Store } from '@ngrx/store';

export const LANG_LS_KEY = 'a1';

@Injectable()
export class InitOptionsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private initOptionsService: InitOptionsService,
    private httpErrorService: HttpErrorService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private keycloakService: KeycloakService
  ) {}

  
  getSettings$ = createEffect(() => this.actions$.pipe(
    ofType(initOptionsActions.loadInitOptions),
    exhaustMap(() =>
      this.initOptionsService.getInitOptions().pipe(
        tap((options: InitOptions) => {
          if (options.invokeCreateUser) {
            this.initOptionsService.createUserToken();
          }
        }),
        map((options: InitOptions) => {
          options.locationOptions.dateFormat = options.locationOptions.dateFormat;
          options.locationOptions.thousandSeparator = options.locationOptions.thousandSeparator;
          options.locationOptions.decimalSeparator = options.locationOptions.decimalSeparator;
          options.locationOptions.uiVersion = options.locationOptions.uiVersion.toLowerCase();
          if (options.locationOptions.uiVersion === 'scania') {
            options.locationOptions.dateFormat = 'dd-MM-yyyy';
          }
          // options.locationOptions.uiVersion = 'workspace';
          //options.locationOptions.uiVersion = 'dpd';
          options.locationOptions.translationDir = options.locationOptions.translationDir.toLowerCase();
          return initOptionsActions.setInitOptions({ options });
        }),
        catchError((error) => of(initOptionsActions.loadInitOptionsError({ error })))
      )
    )
  ));

  
  setLangs$ = createEffect(() => this.actions$.pipe(
    ofType(initOptionsActions.setInitOptions),
    map(({ options }) => {
      const currentLang = options.locationOptions.defaultLang;
      this.translateService.langs = options.locationOptions.langList;

      return langActions.changeCurrent({ currentLang });
    })
  ));

  
  mfaRequired$ = createEffect(() => this.actions$.pipe(
    ofType(initOptionsActions.setInitOptions),
    filter(({ options }) => options.multiFactorAuthenticationRequired),
    switchMap(({ options }) =>
      this.dialog
        .open(MultiFactorAuthenticationDialogComponent, { disableClose: true, backdropClass: 'backdrop-blur' })
        .afterClosed()
        .pipe(switchMap(() => [initOptionsActions.mfaDialogClosed()]))
    )
  ));

  
  loadConsents$ = createEffect(() => this.actions$.pipe(
    ofType(initOptionsActions.setInitOptions),
    filter(({ options }) => !options.multiFactorAuthenticationRequired),
    tap(() => {
      this.store$.dispatch(appUnreadMessagesActions.loading());
    }),
    filter(() => this.keycloakService.isUserInRole(Permission.PERM_PROFILE_CONSENTS_EDIT)),
    switchMap(({ options }) => [toDecideConsentListActions.loading()])
  ));

  
  loadConsentsForMfa$ = createEffect(() => this.actions$.pipe(
    ofType(initOptionsActions.mfaDialogClosed),
    tap(() => {
      this.store$.dispatch(appUnreadMessagesActions.loading());
    }),
    filter(() => this.keycloakService.isUserInRole(Permission.PERM_PROFILE_CONSENTS_EDIT)),
    switchMap(() => [toDecideConsentListActions.loading()])
  ));

  
  errorGetSettings$ = createEffect(() => this.actions$.pipe(
    ofType(initOptionsActions.loadInitOptionsError),
    exhaustMap(({ error }) =>
      this.httpErrorService.showError(error).pipe(map(() => initOptionsActions.loadInitOptions()))
    )
  ));
}
