<div [ngClass]="{'checked' : recordsSizeByPage > 0 || allRecords}" class="table__header grid" fxLayoutGap="10px">
  <div class="checkbox-list">
    <mat-checkbox *ngIf="checkAllVisible"
                  (change)="handleCheckAllRecords.emit($event)"
                  [checked]="(allRecords && isAllSelected)"
                  [indeterminate]="!checkVisible && recordsSizeByPage >0 && !isAllSelected"
    >{{ 'APP.TABLE.CHECK_ALL' | translate }}</mat-checkbox>

    <mat-checkbox (change)="handleToggleAllCheck.emit($event)" *ngIf="checkVisible && !allRecords"
                  [checked]="isAllSelected"
                  [indeterminate]="recordsSizeByPage >0 && !isAllSelected">
      {{ 'APP.TABLE.CHECK_VISIBLE' | translate }}
    </mat-checkbox>
  </div>
  <div class="center">
    <span *ngIf="size > 0"
          class="table__checked">{{ 'APP.TABLE.SELECTED_ITEMS' | translate }}:
      <b>{{ size }}</b>
    </span>
  </div>

</div>
