<div *ngIf="length !== 0" class="paginator" fxLayoutAlign="end baseline">
  <div class="paginator__item">{{'APP.PAGINATION.RESULTS' | translate }}: <span
    class="paginator__item--value">{{ length }}</span></div>
  <div class="paginator__item">
    <mat-form-field class="select-pages">
      <mat-label>{{'APP.PAGINATION.PAGE_SIZE' | translate }}:</mat-label>
      <mat-select [formControl]="pageControl">
        <mat-option *ngFor="let item of pageSizeOptions" [value]="item">{{ item }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="paginator__item" style="display: flex;">
    <button *ngIf="!isFirst" mat-icon-button (click)="onBackClick()" [disabled]="isFirst">
      <mat-icon class="paginator-icon" svgIcon="paginator-left"></mat-icon>
    </button>
    <div class="input-pages" fxLayoutAlign="center center">
      <input type="text" [min]="pages" [formControl]="currentPageControl" [value]="+appCurrentPage">
      <span>{{ ('APP.PAGINATION.OF' | translate) +' '+ pages }}</span>
    </div>
    <button *ngIf="!isLast" mat-icon-button (click)="onNextClick()" [disabled]="isLast">
      <mat-icon class="paginator-icon" svgIcon="paginator-right"></mat-icon>
    </button>
  </div>
  <div class="paginator__item">
  </div>
</div>
