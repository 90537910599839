import { createSelector } from '@ngrx/store';
import { consentsFeatureSelector, ConsentsState } from '@store/consents/consents.reducer';


const selector = createSelector(
  consentsFeatureSelector,
  (state: ConsentsState) => state.updateDecision,
);

export const loading = createSelector(
  selector,
  (state) => state.loading,
);

export const data = createSelector(
  selector,
  (state) => state.data,
);

export const success = createSelector(
  selector,
  (state) => state.success,
);

export const errors = createSelector(
  selector,
  (state) => state.errors,
);

export const updateConsentDecisionSelectors = {
  loading,
  errors,
  success,
  data,
};
