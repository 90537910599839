import { AfterViewInit, Component, HostBinding, Inject, PLATFORM_ID } from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, share, takeUntil, throttleTime } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BaseComponent } from '@shared/abstract/base-component.abstract';

export enum Direction {
  Up = 'Up',
  Down = 'Down'
}

export enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: [ './sticky-header.component.scss' ],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' }),
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' }),
      ),
      transition('* => *', animate('200ms ease-in')),
    ]),
  ],
})
export class StickyHeaderComponent extends BaseComponent implements AfterViewInit {

  private isVisible = false;

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  constructor(@Inject(PLATFORM_ID) private platformId) {
    super();
  }

  ngAfterViewInit(): void {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      map((val: number) => val >= 350 ? Direction.Up : Direction.Down),
      distinctUntilChanged(),
      share(),
    );

    const scrollUp$ = scroll$.pipe(
      filter((direction: Direction) => direction === Direction.Up),
    );

    const scrollDown = scroll$.pipe(
      filter((direction: Direction) => direction === Direction.Down),
    );

    scrollUp$.pipe(takeUntil(this.destroyed$)).subscribe(() => (this.isVisible = true));
    scrollDown.pipe(takeUntil(this.destroyed$)).subscribe(() => (this.isVisible = false));
  }
}
