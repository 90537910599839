import { createReducer, on } from '@ngrx/store';
import { BaseState } from '@store/_common/models/base-state';
import { get } from 'lodash-es';
import produce from 'immer';

const initialState: BaseState = {};

export function operationReducer(types) {
  return createReducer(
    initialState,
    on(types.loading, (state) =>
      produce(state, (draftState) => {
        draftState.loading = true;
      }),
    ),
    on(types.loaded, (state, payload) =>
      produce(state, (draftState) => {
        draftState.loading = false;
        draftState.success = true;
        draftState.data = get(payload, 'response');
      }),
    ),
    on(types.failed, (state, payload) =>
      produce(state, (draftState) => {
        draftState.loading = false;
        draftState.success = false;
        draftState.errors = get(payload, 'error');
      }),
    ),
    on(types.clear, (state) =>
      produce(state, (draftState) => {
        draftState.loading = false;
        draftState.success = null;
        draftState.errors = null;
        draftState.data = null;
      }),
    ),
  );
}
