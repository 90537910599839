import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from '@shared/modules/pipes/number.pipe';
import { Store } from '@ngrx/store';
import { initOptionsSelectors } from '@store/app/init-options/init-options.selectors';
import { tap, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'appNumberLocale',
})
export class AppNumberLocalePipe extends NumberPipe implements PipeTransform {
  thousandSeparator = ' ';
  decimalSeparator = ',';

  constructor(private readonly store: Store) {
    super();
    this.store
      .select(initOptionsSelectors.thousandSeparator)
      .pipe(
        withLatestFrom(this.store.select(initOptionsSelectors.decimalSeparator)),
        tap(([thousandSeparator, decimalSeparator]) => {
          this.thousandSeparator = thousandSeparator;
          this.decimalSeparator = decimalSeparator;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  transform(value: number): string | null {
    return value || value === 0 ? super.transform(value, 2, 2, this.thousandSeparator, this.decimalSeparator) : null;
  }
}
