import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, combineReducers, createFeatureSelector } from '@ngrx/store';
import { BaseListState } from '@store/_common/models/base-list-state';
import { listReducer } from '@store/_common/reducers/list.reducer';
import { consentListActions } from '@store/consents/actions/consent-list.actions';
import { BaseState } from '@store/_common/models/base-state';
import { operationReducer } from '@store/_common/reducers/operation.reducer';
import { updateConsentDecisionActions } from '@store/consents/actions/update-consent-decision.actions';
import { toDecideConsentListActions } from '@store/consents/actions/to-decide-consent-list.actions';

export interface ConsentsState {
  consentList: BaseListState;
  toDecideConsentList: BaseListState;
  updateDecision: BaseState;
}

const reducers = combineReducers({
  consentList: listReducer(consentListActions, undefined),
  toDecideConsentList: listReducer(toDecideConsentListActions, undefined),
  updateDecision: operationReducer(updateConsentDecisionActions),
});

export function getReducers() {
  return reducers;
}

export const consentsReducerKey = 'consents';

export const consentsFeatureSelector = createFeatureSelector(consentsReducerKey);

export const CONSENTS_REDUCER_TOKEN = new InjectionToken<ActionReducer<ConsentsState>>(consentsReducerKey);

export const consentsProvider: Provider = {
  provide: CONSENTS_REDUCER_TOKEN,
  useFactory: getReducers,
};
