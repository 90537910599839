<div class="carousel" @carouselAppear >
  <!-- slides -->
  <div *ngFor="let slide of slides; let i = index" class="carousel__slide">
    <img
      *ngIf="i === currentSlide"
      [src]="slide.imageSrc"
      @carouselAnimation
    />
  </div>

  <!-- controls -->
  <button *ngIf="slides?.length > 1 && config.arrows" class="carousel__control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button *ngIf="slides?.length > 1 && config.arrows" class="carousel__control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>

  <div *ngIf="config.dots" class="carousel__dots">
    <button [disabled]="i === currentSlide" *ngFor="let slide of slides; let i = index" (click)="onJumpTo(i)">
      <span></span>
    </button>
  </div>
</div>
