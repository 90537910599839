import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { BaseComponent } from '@shared/abstract/base-component.abstract';

const FIRST_PAGE = 1;

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: [ './paginator.component.scss' ],
})
export class PaginatorComponent extends BaseComponent implements OnChanges {

  form: UntypedFormGroup;

  @Input()
  set currentPage(v: number) {
    this.appCurrentPage = isNil(v) ? 1 : +v;
  }

  @Input()
  set pageSize(v: number) {
    this.appPageSize = isNil(v) ? 10 : +v;
  }

  @Input() length: number;
  @Input() pageSizeOptions = [ 5, 10, 25, 50 ];

  @Output() pageChanged = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();

  appCurrentPage: number;
  appPageSize: number;

  get pages(): number {
    return Math.ceil(this.length / this.appPageSize) || 0;
  }

  get isFirst() {
    return this.appCurrentPage === FIRST_PAGE;
  }

  get isLast() {
    return this.appCurrentPage === this.pages;
  }

  pageControl: UntypedFormControl;
  currentPageControl: UntypedFormControl;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.length && this.currentPageControl) {
      this.currentPageControl.setValidators([ Validators.max(this.pages), Validators.min(1) ]);
    }
  }

  ngOnInit(): void {
    this.createForm();

    this.currentPageControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter((value: number) => !isNil(value) && value > 0 && value <= this.pages),
    ).subscribe((val: number) => {
      this.pageChanged.emit({
        currentPage: +val,
      });
    });

    this.pageControl.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyed$),
    ).subscribe((val: number) => {
      this.pageChanged.emit({
        currentPage: 1,
        pageSize: +val,
      });
    });
  }

  onNextClick(): void {
    this.appCurrentPage++;
    this.pageChanged.emit({
      currentPage: this.appCurrentPage,
    });
  }

  onBackClick(): void {
    this.appCurrentPage--;
    this.pageChanged.emit({
      currentPage: this.appCurrentPage,
    });
  }

  private createForm(): void {
    this.currentPageControl = this.fb.control(this.appCurrentPage, [ Validators.max(this.pages), Validators.min(1) ]);
    this.pageControl = new UntypedFormControl(this.appPageSize || 10);
  }

}
