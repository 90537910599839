import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ExceptionUrls, isUrlOnExceptionList } from '../utils/interceptor.utils';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  private readonly urlExceptions: ExceptionUrls = [
    { partUrl: 'assets' },
    { partUrl: 'assets/images/icons' },
    { partUrl: 'Logs/log-front-event' },
  ];

  constructor(private http: HttpClient, private tenantConfigService: TenantConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isUrlOnExceptionList(this.urlExceptions, req) || !this.tenantConfigService.logFrontErrors) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.http
          .post<void>('/api/core/user/Logs/log-front-event', {
            code: 'HTTP_ERROR',
            description: error?.message || JSON.stringify(error),
            level: 'ERROR',
          })
          .pipe(switchMap(() => throwError(error)));
      })
    );
  }
}

export const loggingInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoggingInterceptor,
  multi: true,
};
