<div class="page__switch toggle">
  <ng-container *ngIf="(uiVersion$ | async) === 'dpd'">
    <button
      #rlaD="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaD.isActive }"
      class="toggle__button"
      routerLink="/documents"
      routerLinkActive>
      {{ 'APP.NAVIGATION.DOCUMENTS' | translate }}
    </button>
    <button
      #rlaCa="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCa.isActive }"
      class="toggle__button"
      routerLink="/contracts-attachments"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CONTRACTS_ATTACHMENTS' | translate }}
    </button>
    <button
      #rlaCpa="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCpa.isActive }"
      class="toggle__button"
      routerLink="/customer-partners-attachments"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CUSTOMER_PARTNERS_ATTACHMENTS' | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="(uiVersion$ | async) === 'ecminfinite'">
    <button
      #rlaCM="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCM.isActive }"
      class="toggle__button"
      routerLink="/contracts/master"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CONTRACTS_MASTER' | translate }}
    </button>
    <button
      #rlaCP="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCP.isActive }"
      class="toggle__button"
      routerLink="/contracts/product"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CONTRACTS_PRODUCT' | translate }}
    </button>
    <button
      #rlaCPL="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCPL.isActive }"
      class="toggle__button"
      routerLink="/contracts/price-list"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CONTRACTS_PRICE_LIST' | translate }}
    </button>
    <button
      #rlaCT="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCT.isActive }"
      class="toggle__button"
      routerLink="/contracts/traffic"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CONTRACTS_TRAFFIC' | translate }}
    </button>
    <button
      #rlaCR="routerLinkActive"
      [ngClass]="{ 'toggle__button--active': rlaCR.isActive }"
      class="toggle__button"
      routerLink="/contracts/retailers"
      routerLinkActive>
      {{ 'APP.NAVIGATION.CONTRACTS_RETAILERS' | translate }}
    </button>
  </ng-container>
</div>
