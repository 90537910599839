import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { intersection } from 'lodash-es';

@Directive({
  selector: '[appHasAccess]',
})
export class HasAccessDirective implements OnInit {

  @Input() appHasAccess: string[];

  constructor(private el: ElementRef, private keycloakService: KeycloakService) {

  }

  ngOnInit(): void {
    if (!Array.isArray(this.appHasAccess)) {
      throw new Error('appHasAccess must be an array');
    }

    const permissions = this.keycloakService.getUserRoles();
    const hasAccess = intersection(permissions, (this.appHasAccess || [])).length === (this.appHasAccess || []).length;

    if (!hasAccess) {
      this.el.nativeElement.remove();
    }

  }

}
